import "./fifithComponent.css";
import { lpTexts } from "../../external/lpTexts";
import ButtonDiagnosis from "../buttonDiagnosis/ButtonDiagnosis";
import Persons from "../persons/Persons";
import { useNavigate } from "react-router-dom";
function FifithComponent() {
  const navigate = useNavigate();
  let texts = lpTexts.fifthComponent;
  return (
    <div className="all-fthc">
      <div className="fthc-top-text">{texts.text1}</div>
      <div className="fthc-persons">
      <Persons />
      </div>
      <div className="fthc-bottom-text">{texts.text2}</div>
      <div
        onClick={() => {
          navigate("/diagnostico");
          window.scrollTo({ top: 0 });
        }}
      >
        <ButtonDiagnosis buttonText="Preencher diagnóstico" />
      </div>
    </div>
  );
}
export default FifithComponent;
