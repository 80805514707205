import "./resultsTop.css";
import DownloadButton from "../buttonDownloadResult/ButtonDownloadResult";
function TopResults() {
  return (
    <div className="all-top">
      <div className="download">
        <div className="text-completed">
          Diagnóstico concluído! Aqui está seu resultado!
        </div>
        <div className="results-top-button">
          <DownloadButton textColor="#738392" backgroundColor="#ffffff" />
        </div>
      </div>
      <div className="devolution">
        <div className="text-devolution">
          Você tem direito a uma DEVOLUTIVA GRATUITA com nossos especialistas
          para compreender com maior profundidade o seu resultado do Diagnóstico
          e já pensar em possiblidades de AÇÕES!
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://calendar.app.google/2ErB87XciAGNBM8G6"
        >
          <button className="button-devolution">Agende sua devolutiva</button>
        </a>
        <hr className="line" />
      </div>
    </div>
  );
}
export default TopResults;
