import React, { useContext } from "react";
import "./answerProgressBar.css";
import { ScrollContext } from "../../contexts/scrollContext";

function AnswerProgressBar({ progressPercentage }) {
  const { progressBarBottom } = useContext(ScrollContext);
  return (
    <>
      <div
        className="progress-bar-background"
        style={{
          bottom: progressBarBottom,
        }}
      >
        <div
          className="progress-bar"
          style={{
            borderRadius: progressPercentage === 100 ? "0px" : "",
            width: `${progressPercentage}%`,
            background: getColor(progressPercentage),
          }}
        />
        <div className="progress-bar-text">
          <span className="progress-percentage">
            Progresso: {progressPercentage}%
          </span>
        </div>
      </div>
    </>
  );
}

function getColor(percentage) {
  if (percentage <= 30) {
    return "#C64D4D";
  } else if (percentage <= 60) {
    return "#FFB827";
  } else {
    return "#26BB3E";
  }
}

export default AnswerProgressBar;
