import { CompanyInformationsProvider } from "./contexts/companyInformationsContext.js";
import { LoadingProvider } from "./contexts/loadingContext.js";
import { QuestionsProvider } from "./contexts/questionsContext.js";
import { ScrollProvider } from "./contexts/scrollContext.js";
import DiagnosisRoutes from "./routes/DiagnosisRoutes.jsx";

function DiagnosisModule() {
  return (
    <ScrollProvider>
      <LoadingProvider>
        <CompanyInformationsProvider>
          <QuestionsProvider>
            <DiagnosisRoutes />
          </QuestionsProvider>
        </CompanyInformationsProvider>
      </LoadingProvider>
    </ScrollProvider>
  );
}

export default DiagnosisModule;
