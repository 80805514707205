import "./fourthComponent.css";
import Steps from "../steps/Steps";
import FirstImg from "../steps/assets/firstImg.svg";
import SecondImg from "../steps/assets/secondImg.svg";
import ThirdImg from "../steps/assets/thirdImg.svg";
import { lpTexts } from "../../external/lpTexts";

function FourthComponent() {
  let texts = lpTexts.fourthComponent;
  return (
    <div className="all-foc">
      <div className="foc-title">{texts.text1}</div>
      <div className="foc-steps">
        <div className="step-1"><Steps
          stepNumber="1"
          stepImg={FirstImg}
          stepTitle={texts.text2}
          stepText={texts.text3
            .split("%")
            .map((part, index) =>
              index % 2 === 1 ? <strong key={index}>{part}</strong> : part
            )}
          width="60%"
          widthMob="55%"
        /></div>
        <div className="step-2"><Steps
          stepNumber="2"
          stepImg={SecondImg}
          stepTitle={texts.text4}
          stepText={texts.text5
            .split("%")
            .map((part, index) =>
              index % 2 === 1 ? <strong key={index}>{part}</strong> : part
            )}
          width="50%"
          widthMob="52%"
        /></div>
        <div className="step-3"><Steps
          stepNumber="3"
          stepImg={ThirdImg}
          stepTitle={texts.text6}
          stepText={texts.text7
            .split("%")
            .map((part, index) =>
              index % 2 === 1 ? <strong key={index}>{part}</strong> : part
            )}
          width="90%"
          widthMob="95%"
        /></div>
      </div>
    </div>
  );
}
export default FourthComponent;
