import InputMask from "react-input-mask";
import "./field.css";

function Field({ inputValue, inputTitle, mask, setter, isValid, errorMessage }) {
  const handleInputChange = (event) => {
    setter(event.target.value);
  };
  const devWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  return (
    <>
    {devWidth > 991 && (
    <div className="field">
      {inputTitle}:
      <InputMask
        value={inputValue}
        mask={mask}
        onChange={handleInputChange}
        className={isValid ? "field-input" : "field-input-invalid"}
      />
      {!isValid && <div className="error">{errorMessage}</div>}
    </div>)}
    {devWidth < 991 && (
    <div className="field-mob">
    <div className="field-mob-title">{inputTitle}:</div>
    <InputMask
      value={inputValue}
      mask={mask}
      onChange={handleInputChange}
      className={isValid ? "mob-field" : "mob-field-invalid"}
    />
    {!isValid && <div className="mob-error">{errorMessage}</div>}
  </div>)}
  </>
  );
}

export default Field;
