import { useState } from "react";
import "./buttonDownloadResult.css";
import DownloadImg from "./assets/download.svg";
import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import coverImg from "./assets/capa.jpg";
import backCoverImg from "./assets/contra-capa.jpg";

function DownloadButton({ backgroundColor, textColor }) {
  const [buttonText, setButtonText] = useState("Baixar Resultado");

  const downloadPdf = async () => {
    setButtonText("Carregando.");
    document.body.classList.add("pdf-generation");
    const pages = [
      {
        id: "pdf-page1",
        proportion: 0.9,
      },
      {
        id: "pdf-page2",
        proportion: 0.6,
      },
      {
        id: "pdf-page3",
        proportion: 0.8,
      },
      {
        id: "pdf-page4",
        proportion: 0.63,
      },
    ];
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    // Adicionar a capa
    const coverImage = new Image();
    coverImage.src = coverImg;
    await coverImage.decode();
    const coverCanvas = document.createElement("canvas");
    coverCanvas.width = coverImage.naturalWidth; // Use a largura original da imagem
    coverCanvas.height = coverImage.naturalHeight; // Use a altura original da imagem
    const coverCtx = coverCanvas.getContext("2d");
    coverCtx.drawImage(coverImage, 0, 0, coverCanvas.width, coverCanvas.height);
    const coverImgData = coverCanvas.toDataURL("image/jpg");
    pdf.addImage(
      coverImgData,
      "PNG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );
    pdf.addPage();
    setButtonText("Carregando..");
    for (let i = 0; i < pages.length; i++) {
      const pageId = pages[i].id;
      const input = document.getElementById(pageId);
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg");

      let imgProps = pdf.getImageProperties(imgData);
      let pdfPageWidth = pdf.internal.pageSize.getWidth();
      let pdfPageHeight = pdf.internal.pageSize.getHeight();
      let pdfWidth = pdfPageWidth * pages[i].proportion;
      let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      let x = (pdfPageWidth - pdfWidth) / 2;
      let y = (pdfPageHeight - pdfHeight) / 2;

      pdf.addImage(imgData, "JPEG", x, y, pdfWidth, pdfHeight);

      // Adicione o ID da página como texto invisível
      pdf.setTextColor(255, 255, 255); // Defina a cor do texto como branco (invisível)
      pdf.text(pageId, 10, 10); // Adicione o ID da página no canto superior esquerdo

      if (i < pages.length - 1) {
        pdf.addPage();
      }
    }
    setButtonText("Carregando...");
    // Adicionar a contra capa
    const backCoverImage = new Image();
    backCoverImage.src = backCoverImg;
    await backCoverImage.decode();
    const backCoverCanvas = document.createElement("canvas");
    backCoverCanvas.width = backCoverImage.naturalWidth; // Use a largura original da imagem
    backCoverCanvas.height = backCoverImage.naturalHeight; // Use a altura original da imagem
    const backCoverCtx = backCoverCanvas.getContext("2d");
    backCoverCtx.drawImage(
      backCoverImage,
      0,
      0,
      backCoverCanvas.width,
      backCoverCanvas.height
    );
    const backCoverImgData = backCoverCanvas.toDataURL("image/jpg");
    pdf.addPage();
    pdf.addImage(
      backCoverImgData,
      "PNG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );

    pdf.save("Resultado_Diagnostico.pdf");
    document.body.classList.remove("pdf-generation");
    setButtonText("Baixar Resultado");
  };

  const styleDownloadButton = {
    backgroundColor: backgroundColor,
    color: textColor,
  };

  return (
    <div className="download-button" onClick={downloadPdf}>
      <button className="button-download" style={styleDownloadButton}>
        <img src={DownloadImg} alt="download" className="download-img" />
        <div className="download-button-text">{buttonText}</div>
      </button>
    </div>
  );
}

export default DownloadButton;
