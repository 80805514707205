export const questionsDb = [
  {
    pillarName: "Pioneirismo",
    questions: [
      {
        valueTitle: "VALOR: INTRAEMPREENDEDORISMO E SENSO DE OPORTUNIDADE",
        valueDescription:
          "Demonstra uma capacidade e um empoderamento dos funcionários para agirem como donos do negócio e aproveitarem oportunidades. Quando este valor está presente os colaboradores ultrapassam o que é esperado e trazem suas contribuições pessoais para a empresa.",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - Nossos profissionais devem apenas se ater a descrição do cargo e cumprir suas funções específicas. Comportamentos que fogem à este padrão são malvistos dentro da empresa.",
          "2 - Novas ideias e abordagens de negócios são bem vindas mas estão circunscritas apenas à alta liderança.",
          "3 - Valorizamos uma atitude de pensar fora da caixa e propor novas soluções, mesmo fora da liderança, mas não reconhecemos os profissionais que agem desta maneira e estes profissionais encontram dificuldade para colocar estas ideias em prática.",
          "4 - Valorizamos e reconhecemos profissionais que agem como “dono do negócio” e que questionam o status quo e propõe novas soluções para o negócio. É comum que estes profissionais encontrem alguma oportunidade de desenvolver estas ideias na empresa.",
          "5 - Nossos profissionais tem tempo e recursos para criarem e se dedicarem a projetos de interesse pessoal e que contribuam para a empresa, pensando e agindo como dono do negócio o tempo todo. Esperamos dos nossos profissionais a proposição de novas soluções e fornecemos as condições para tirar estas ideias do papel.",
        ],
      },
      {
        valueTitle: "VALOR: SEM FRONTEIRAS - SISTEMA ABERTO",
        valueDescription:
          "Hoje em dia ninguém possui mais o monopólio do conhecimento. O conhecimento, a inovação e a geração de valor são distribuídos em cadeias cada vez mais complexas. As empresas que já internalizaram isto e aprenderam a fazer conexões efetivas com o mundo lá fora saem na frente quando se trata de inovação.",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - Minha empresa não participa de projetos colaborativos envolvendo outras instituições ou polos de inovação (clientes, universidades, centros de pesquisa, etc). Não buscamos solucionar os desafios da inovação com participação de atores externos à organização.",
          "2 - Gostaríamos de solucionar os desafios da inovação com participação de atores externos à organização, mas ainda não possuímos nenhum projeto neste sentido e não entendemos como podemos colocar isto em prática.",
          "3 - Em minha empresa estamos firmando as primeiras parcerias em projetos colaborativos voltados à inovação. A empresa está convencida da importância disto, mas ainda possui poucos resultados concretos.",
          "4 - Em minha empresa temos algumas parcerias em projetos colaborativos voltados à inovação. A liderança valoriza estas práticas e já colhe os resultados de algumas iniciativas, mas ainda é algo restrito a alguns setores ou poucos projetos.",
          "5 - As competências e as soluções no campo da inovação são buscadas várias vezes fora da empresa, mediante processo estruturado de avaliação que considera as vantagens/desvantagens. Desta forma, a empresa participa com frequência de projetos colaborativos envolvendo outras instituições e preserva conexões com o ecossistema de inovação.",
        ],
      },
      {
        valueTitle: "VALOR: EXPERIMENTALISMO",
        valueDescription: `É impossível  inovar sem experimentar. Este valor trata da importância de tentar e testar a maior variedade de ideias possíveis explorando o "fora da caixa". No mundo em que vivemos é fundamental errar rápido para aprender mais rápido ainda.`,
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - A liderança não está aberta a experimentar novas soluções, trazemos soluções prontas do mercado sem considerar como poderíamos nos diferenciar. Raramente consideramos mudar algum de nossos processos.",
          "2 - A empresa realiza pequenos testes / ajustes quando não há outras alternativas, mas isto requer uma grande burocracia para aprovação da liderança e é desestimulado.",
          "3 - A empresa realiza experimentos e testes para o desenvolvimento de novas soluções, mas ainda possui pouca estrutura e experiência para conseguir resultados palpáveis destas ações.",
          `4 - A empresa realiza experimentos e testes para o desenvolvimento de novas soluções, possui estrutura para tal, mas ainda nos atemos a testar inovações incrementais, não experimentando tanto com soluções "fora da caixa".`,
          "5 - Nossos funcionários são estimulados constantemente a criar e experimentar soluções através de protótipos e testes. Possuímos experiência e infraestrutura para tal, e experimentamos tanto inovações incrementais como radicais.",
        ],
      },
      {
        valueTitle: "VALOR: PENSAR E FAZER DIFERENTE",
        valueDescription:
          'É muito comum que as empresas se restrinjam a fazer todos os procedimentos como sempre foram feitos, para "otimizar" os resultados. No entanto, num mundo cada vez mais competitivo, é fundamental que as empresas estejam sempre se reinventando e desafiando os padrões. Este mindset é fundamental para qualquer inovação.',
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - Em nossa empresa regras e procedimentos são valorizados e punimos quem procura fazer diferente.",
          "2 - Em nossa empresa estamos abertos a sugestões sobre como fazer melhor e diferente, mas isto é algo que raramente acontece.",
          "3 - Em nossa empresa buscamos nos reinventar e isto é algo que acontece ocasionalmente. No entanto, não possuímos práticas para dar vazão às ideias de melhorias.",
          "4 - Em nossa empresa possuímos processos e um canal dedicado para os funcionários sugerirem mudanças e ações diferentes das originalmente previstas. Nosso foco é em resultados e estamos abertos a atingi-los por outros caminhos.",
          "5 - Em nossa empresa valorizamos pessoas que buscam mudar e melhorar sempre e não aceitam o status quo. Valorizamos quem possui uma postura e um posicionamento mental de inconformismo bom e permanente.",
        ],
      },
    ],
  },
  {
    pillarName: "Times inovadores",
    questions: [
      {
        valueTitle: "VALOR: PESSOAS EM PRIMEIRO LUGAR",
        valueDescription:
          "Quando está presente a empresa reconhece que o que impulsiona a organização são as relações interpessoais. Isso se traduz em um cuidado e atenção com colaboradores e clientes, reconhecendo as necessidades, valores e sentimentos de cada individuo, e fazendo o possível para tornar a vida das pessoas melhor. ",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - A empresa possui foco apenas nos processos e resultados financeiros. Não existe preocupação legítima com a gestão das pessoas e com o clima organizacional.",
          "2 - Existe interesse da liderança em manter um bom clima organizacional, mas essa questão acaba não sendo prioridade e, na prática, os profissionais acabam não sendo valorizados.",
          "3 - A liderança ouve as demandas dos funcionários, e tenta promover ações para valorizá-los, mas estas ações acabam sendo bastante restritas.",
          "4 - A empresa possui um programa para desenvolver ações com foco na melhoria do ambiente de trabalho e motivação dos funcionários.",
          "5 - Nossa empresa coloca as pessoas em primeiro lugar, promove a transparência, tratando a todos com respeito e atenção e considerando cada profissional como um inovador em potencial. A empresa é humana e propicia as condições necessárias para que as pessoas sejam felizes e gerem resultados.",
        ],
      },
      {
        valueTitle: "VALOR: DIVERSIDADE",
        valueDescription:
          "Vivemos em um mundo extremamente diverso, e as empresas que querem ter sucesso neste mundo devem reproduzir esta diversidade internamente. Pesquisas demonstram que diferentes tipos de diversidade trazem maior efetividade no desenvolvimento de soluções e na tomada de decisões. Os diferentes pontos de vista que a diversidade traz são preciosos para o processo de inovação.",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - Em nossa organização, possuímos, principalmente nos cargos de liderança, pessoas oriundas das mesmas classes sociais, raça e gênero. O assunto não é muito debatido e as pessoas são predominantemente contra alguma mudança neste sentido.",
          "2 - Na organização existem opiniões divergentes sobre o apoio à diversidade. Há muitos que apoiam uma empresa mais diversa e muitos que são contra. No entanto, ainda não se discute tomar medidas para aumentar a diversidade na equipe.",
          "3 - Em nossa organização a diversidade é predominantemente bem vista pelas pessoas. Queremos implementar mudanças neste sentido e o assunto é por vezes debatido. No entanto, não possuímos práticas que a garantam e acabamos formando uma equipe pouco diversa.",
          "4 - Nossa liderança apoia a diversidade ativamente. As pessoas, como parte de um pensamento difundido na empresa, valorizam todos os tipos de diversidade. Há praticas para garantir a diversidades nas equipes. No entanto, ainda não se verifica que está valorização foi capaz de garantir uma equipe efetivamente diversa em todos os níveis hierárquicos.",
          "5 - Nossa organização é um modelo de diversidade, o que se traduz tanto na mentalidade das pessoas como nas práticas da empresas. Temos politicas bem definidas para garantir a diversidade social, de gênero e de raça, e isto se verifica em todos os níveis hierárquicos.",
        ],
      },
    ],
  },
  {
    pillarName: "Mente aberta",
    questions: [
      {
        valueTitle: "VALOR: ERRO COMO APRENDIZADO",
        valueDescription:
          "Nos fala da importância dos erros para o crescimento. Empresas com aversão ao erro tendem a desperdiçar ótimas oportunidades, enquanto empresas que aprendem com o erro se fortalecem e se tornam mais robustas a cada tentativa sem sucesso.",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - As iniciativas que não obtiveram sucesso são duramente criticadas e mal vistas dentro da empresa",
          "2 - As iniciativas que não obtêm sucesso são criticadas de forma branda, mas não há o objetivo de se extrair o aprendizado a partir do erro.",
          "3 - As iniciativas que não obtêm sucesso são criticadas, mas em alguns casos são utilizadas para permitir correções e aperfeiçoamentos futuros.",
          "4 - A liderança vê o erro como parte do processo de aprendizado. As iniciativas que não obtêm sucesso são analisadas e utilizadas em alguns casos como experiência para permitir correções e aperfeiçoamentos futuros.",
          "5 - A liderança vê o erro como parte do processo de aprendizado. A experiência é construída sempre levando em consideração os erros cometidos anteriormente, utilizando-os como recurso para resolver os problemas.",
        ],
      },

      {
        valueTitle: "VALOR: PERSEVERANÇA/RESILIÊNCIA",
        valueDescription:
          "O mundo dos negócios não é para os fracos. Para implementar algo inovador é necessário percorrer um caminho de muita perseverança, possuindo foco no objetivo a ser alcançado e não se deixando abalar para os desafios que certamente virão.",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - Possuímos uma cultura rígida e sofremos muito para nos adaptar frente a novos desafios. Quando enfrentamos desafios há uma desestabilização emocional na empresa e nos ficamos nervosos ou pessimistas. Quando alguma iniciativa parece dar errado geralmente desistimos dela.",
          "2 - Possuímos dificuldade em aprender com as adversidades e se adaptar. Quando enfrentamos grandes adversidades demoramos para nos recompor. Frente à iniciativas que não vão bem, conseguimos nos manter focado em gerar resultados por um tempo, mas, persistindo os problemas, desanimamos.",
          "3 - Sabemos lidar com as adversidades e nos mantemos focados mesmo que as coisas não estejam indo bem. Há um certo receio da equipe quando surgem desafios, mas não deixamos que isso nos abale. No entanto, temos dificuldade em nos adaptar, aprender com os erros e nos reinventar frente às dificuldades.",
          "4 - Nossos profissionais sabem lidar com as adversidades e geram lições aprendidas a cada vez que as enfrentam para superar novos desafios que certamente virão. Lidar com situações complicadas não é algo que abala a equipe.",
          "5 - Nossos profissionais sabem lidar muito bem com os problemas e superar obstáculos sem perder velocidade e efetividade diante dos desafios. Quando enfrentamos adversidades ficamos ainda mais motivados a darmos o melhor de nós mesmos, respondendo aos desafios de maneira inteligente. Possuímos garra e somos adaptáveis",
        ],
      },

      {
        valueTitle: "VALOR: CONHECIMENTO COMO VALOR",
        valueDescription:
          "Conhecimento é a base da inovação. E as empresas geram conhecimento a todo momento. No entanto, nem todas elas são capazes de absorver e processar todo este conhecimento. Ter conhecimento como valor é ter uma cultura de reconhecer, valorizar e disseminar todo tipo de conhecimento gerado.",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - Não há investimentos ou práticas em gestão do conhecimento. Não acreditamos que estejam diretamente ligados ao fomento da inovação. A empresa raramente oferece programas de treinamento para melhorar a capacitação técnica do seu público interno.",
          "2 - Não há práticas para a gestão do conhecimento apesar de sabermos que poderiam ajudar a fomentar a inovação. A empresa oferece pontualmente programas de treinamento para melhorar a capacitação técnica dos seus funcionários.",
          "3 - Há alguma prática de gestão do conhecimento e programas de treinamento. A liderança entende o valor destes processos, mas o assunto acaba por não ser priorizado, e ainda enfrentamos problemas para capturar e disseminar o conhecimento na empresa.",
          "4 - Há práticas para a gestão do conhecimento. A empresa oferece vários programas de capacitação técnica e comportamental para seus funcionários. A frequência com que estes programas ocorrem notavelmente acima da média e há ações participativas, tais como fóruns, seminários, debates etc.",
          "5 - Existe um robusto sistema de gestão do conhecimento que serve de base para a inovação. A empresa oferece um conjunto de programas de treinamento, internos e externos, visando a melhorar a capacitação dos seus funcionários. São frequentemente promovidas ações, tais como fóruns, seminários, debates etc. Estas iniciativas se apoiam em nossa visão de que o aprendizado permanente é um dos combustíveis para que uma organização se mantenha inovadora.",
        ],
      },

      {
        valueTitle: "VALOR: RESPEITAR E ACOLHER OBJEÇÕES",
        valueDescription:
          "Em uma organização em que ninguém discorda, nunca se faz nada diferente. A capacidade de respeitar as objeções, estabelecer um diálogo produtivo e chegar em consensos é fundamental para se obter uma visão mais abrangente, que não dependa da avaliação de apenas um grupo de pessoas.",
        questionTitle:
          "Avalie o quanto este valor está presente na sua empresa:",
        questionOptions: [
          "1 - Discordar não é uma atitude bem vista na companhia. As pessoas evitam discordar por receio de prejudicar sua imagem e reputação.",
          "2 - Discordar é difícil na maioria das vezes. Apesar da discordância ser aceita, é bastante difícil que as pessoas realmente mudem de opinião e vejam o outro lado das questões.",
          "3 - Dar pontos de vista divergentes é aceito na empresa. Apesar de haver situações delicadas, geralmente não nos sentimos pressionados a aceitar a opinião mais aceita.",
          "4 - Em nossa organização nos sentimos livres para dar nossos pontos de vista, mesmo que divergentes, na maioria das situações. A empresa entende a importância disto e geramos debates construtivos através da discordância.",
          "5 - É uma prática corrente, disseminada na empresa ouvir buscar ativamente os diversos pontos de vista sobre algum assunto dentro da organização. Independentemente do nível hierárquico, as opiniões diversas são analisadas e cuidadosamente debatidas. É comum que a partir do debate entre pontos de vistas opostos as pessoas mudem de opinião e atinjam um consenso.",
        ],
      },
    ],
  },
];
