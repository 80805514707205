import Footer from "../footer/Footer";
import NavBar from "../navbar/NavBar";

function Scaffold({ children, navbarText }) {
  const devWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (navbarText === "Workshop “Cultura para Inovação”" && devWidth > 991) {
    navbarText = "Workshop “Cultura para Inovação”";
  } else if (
    navbarText === "Workshop “Cultura para Inovação”" &&
    devWidth < 991
  ) {
    navbarText = "Diagnóstico de cultura";
  } else if (devWidth > 991) {
    navbarText = "Diagnóstico de cultura (Sem custo)";
  } else {
    navbarText = "Diagnóstico de cultura";
  }
  return (
    <>
      <NavBar navbarText={navbarText} />
      {children}
      <Footer />
    </>
  );
}

export default Scaffold;
