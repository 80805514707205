import { useEffect, useState } from "react";
import "./contactButton.css";

function ContactButton() {
  const [scrollDiv, setScrollDiv] = useState("");

  useEffect(() => {
    const element = document.getElementById("scroll-div");
    setScrollDiv(element);
  }, []);

  return (
    <div
      className="all-contact-button"
      onClick={() => {
        scrollDiv.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <button className="contact-button">Quero entrar em contato</button>
    </div>
  );
}
export default ContactButton;
