import "./personInformation.css";
import CloseX from "./assets/closeX.svg";
import PersonPhoto from "../personPhoto/PersonPhoto"
import ArrowDown from "./assets/arrowScrollDown.svg"
function PersonInformation({ imgSvg, name, text, closeInformation, photoMargin }) {
  const devWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  return (
    <div className="all-pinfo">
    <div className="all-pinfo-shadow" onClick={closeInformation}>
    </div>
      <div className="all-pinfo-infos">
        <div className="pinfo-left">
          <div className="pinfo-person-photo">
          <PersonPhoto imgSvg={imgSvg} width="200px" height="200px" photoMargin={photoMargin}/>
          </div>
          <div className="pinfo-name">{name}</div>
        </div>
        <div className="pinfo-line">
          <hr className="pinfo-line-line" />
        </div>
        <div className="pinfo-text">{text}</div>
        <div className="pi-close-x" onClick={closeInformation}>
          <img src={CloseX} alt="" />
        </div>
        {devWidth < 991 && (<div className="arrow-down-pi"><img src={ArrowDown} alt=""/></div>)}
      </div>
    </div>
  );
}
export default PersonInformation;
