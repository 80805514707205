import "./topWorkshop.css";
import { workshopTexts } from "../../external/workshopTexts";
import WorkshopTitleText from "../../../shared/workshop/WorkshopText";
import ConsultantsIMG from "./assets/consultantsIMG.svg";
import ConsultantsIMGMob from "./assets/consultantsIMGMob.svg";
import ContactButton from "../contactButton/ContactButton";
function TopWorkshop() {
  let texts = workshopTexts;
  const devWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return (
    <>
      {devWidth > 991 && (
        <div className="all-top-ws">
          <div className="left-top-ws">
            <WorkshopTitleText />
            <div className="top-ws-title">
              <strong>{texts.topics.title}</strong>
            </div>
            <div className="top-ws-topics">
              <div>{texts.topics.topics.text1}</div>
              <div>{texts.topics.topics.text2}</div>
              <div>{texts.topics.topics.text3}</div>
              <div>{texts.topics.topics.text4}</div>
              <div>{texts.topics.topics.text5}</div>
              <div>{texts.topics.topics.text6}</div>
              <div>{texts.topics.topics.text7}</div>
            </div>
            <div className="button-contact-ws">
              <ContactButton />
            </div>
          </div>
          <div className="right-top-ws">
            <img src={ConsultantsIMG} alt="" />
          </div>
        </div>
      )}
      {devWidth < 991 && (
        <div className="all-top-wsmob">
          <div className="ws-mob-left">
            <div className="top-ws-mob-content">
            <div className="top-wsmob-titletext">
              <WorkshopTitleText color="#fff"/>
              </div>
              <div className="top-wsmob-title">
              <strong>{texts.topics.title}</strong>
            </div>
            <div className="top-wsmob-topics">
              <div>{texts.topics.topics.text1}</div>
              <div>{texts.topics.topics.text2}</div>
              <div>{texts.topics.topics.text3}</div>
              <div>{texts.topics.topics.text4}</div>
              <div>{texts.topics.topics.text5}</div>
              <div>{texts.topics.topics.text6}</div>
              <div>{texts.topics.topics.text7}</div>
            </div>
          </div>
          </div>
          <div className="ws-mob-img">
            <img src={ConsultantsIMGMob} alt=""/>
          </div>
        </div>
      )}
    </>
  );
}
export default TopWorkshop;
