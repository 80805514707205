import { WorkshopProvider } from "./context/workshopContext";
import WorkshopRoutes from "./routes/WorkshopRoutes";
import { LoadingProvider } from "../diagnosis/contexts/loadingContext";
function WorkshopModule() {
  return (
    <LoadingProvider>
      <WorkshopProvider>
        <WorkshopRoutes />
      </WorkshopProvider>
    </LoadingProvider>
  );
}
export default WorkshopModule;
