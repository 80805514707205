export const lpTexts = {
  firstComponent: {
    text1: "CULTURA PARA INOVAÇÃO",
    text2:
      "Transforme a Cultura da sua empresa para potencializar os resultados por meio da Inovação!",
    text3:
      "Identifique as oportunidades de adequação da cultura da sua organização e implemente planos de ação, considerando os três pilares:",
    text4:
      "Seja o protagonista da mudança de cultura da sua empresa, entenda como o diagnóstico impacta nos resultados",
  },
  secondComponent: {
    text1: "Para que serve?",
    text2:
      "Identifique os pontos fortes e fracos da Cultura de Inovação na sua empresa",
    text3:
      "Entenda sobre o que é uma Cultura de Inovação ideal e como criar um ambiente favorável à Inovação",
    text4:
      "Defina as prioridades a serem trabalhadas na empresa para potencializar os resultados",
    text5: "Para quem é?",
    text6: "Gestores de inovação",
    text7: "Alta liderança",
    text8: "Empreendedores",
    text9: "Colaboradores",
    text10: "Ou seja, %você!%",
    text11: "*Independente do porte da empresa",
  },
  thirdComponent: {
    text1: "Nosso diagnóstico se diferencia pelos seguintes aspectos:",
    text2: "Base Metodológica",
    text3:
      "por meio de pesquisa e cases com empresas foi possível sistematizar a Cultura de Inovação “ideal” em valores",
    text4: "+ 30 anos",
    text5:
      "de experiência com empresas de diferentes setores e práticas de inovação",
  },
  fourthComponent: {
    text1: "O Diagnóstico contempla 3 etapas:",
    text2: "Cadastro",
    text3: "Vamos precisar apenas do seu %nome, e-mail, cargo e empresa%",
    text4: "Preenchimento do diagnóstico",
    text5: "Responda %10 perguntas%, não vai levar mais que %10 minutos%",
    text6: "Resultado",
    text7:
      "Identifique as principais %oportunidades% para %potencializar a Cultura de Inovação% da sua empresa",
  },
  fifthComponent: {
    text1:
      "Aprimore sua cultura de inovação com apoio de nossos especialistas que contam com expertise e experiências diversas",
    text2:
      "Após o diagnóstico, você terá direito a uma reunião de devolutiva gratuita de 30 minutos com um de nossos especialistas para se aprofundar no resultado.",
    person1: {
      name: "Valter Pieracciani",
      job: "CEO - Fundador",
      text: "Empresário, consultor, pesquisador e escritor. É reconhecido mundialmente como um dos maiores especialistas em inovação e modelos inovadores de gestão. Em 1992 fundou a Pieracciani Desenvolvimento de Empresas, é sócio-diretor. Dirigiu mais de 800 projetos em companhias-líderes como Nestlé, Ambev, Tetrapak, Pirelli e Avon, dentre outras. Atua como gestor de startup e recuperação de empresas. Foi diretor-geral da ABNT à frente da histórica recuperação da entidade. Por mais de 10 anos, serviu os governos Municipal, Estadual e Federal. Iniciou carreira em 1978 na área de P&D. Autor dos livros “Usina de Inovações” e “Qualidade não é mito e dá certo” e “A Verdadeira mágica”. É coordenador de projetos sênior do CLAEQ Centro Latinoamericano para a Inovação, Excelência e Qualidade. É coordenador do projeto ProVA – Laboratório de Inovação para o Varejo (ABDI/Ministério da Economia). Mestre em Administração de Empresas, engenheiro, administrador de empresas e pós-graduado em administração industrial.",
    },
    person2: {
      name: "Daniel Rodrigues",
      job: "Sócio-diretor",
      text: "Sócio-Diretor da Pieracciani Desenvolvimento de Empresas. Especialista em gestão da inovação e educação corporativa. Facilitador de projetos de inovação/ Técnico sênior do projeto ProVA – Laboratório de Inovação para o Varejo (ABDI/Ministério da Economia). Coordenador do inédito programa IBELT de capacitação e certificação de agentes da inovação. Trabalha com projetos de captação de fomento à inovação. Nos últimos anos, Daniel tem liderado programas de Inovação Aberta, processos de Gestão da Inovação, de startups e formulação de estratégia e visão de futuro em empresas-líderes nacionais e multinacionais. Administrador de Empresas pela Universidade de São Paulo e pela Escola Superior de Comércio de Marseille – Euromed (França). Mestre em Empreendedorismo pelo Programa de Mestrado Profissional em Empreendedorismo da Faculdade de Economia e Administração da Universidade de São Paulo.",
    },
    person3: {
      name: "Pâmella Pedroso",
      job: "Gerente de consultoria",
      text: "Formada em técnica de administração de empresas, Engenharia de Produção no Centro Universitário da FEI, e MBA em UX Design & Strategy na FIAP.Coordenadora da área de consultoria na Pieracciani, Atua há mais de 8 anos na área de Gestão da Inovação. Neste período pôde gerenciar grandes empresas no Programa de Incentivos à Inovação Tecnológica, na área de Funding para Inovação; além de atuar em projetos de fomento à inovação, envolvendo conceitos de estratégia, governança corporativa, programas de Inovação Aberta, processos de Gestão da Inovação, projetos de User Experience (UX) e Tendências.Na Pieracciani, pôde desenvolver conhecimentos sobre produtos e processos de diversos setores, como o de alimentos e bebidas, energia, TI, embalagens e automotivo. Além disso, possui experiências anteriores na área de Engenharia do Desenvolvimento do Produto e Engenharia Civil.",
    },
    person4: {
      name: "Victor Rodrigues",
      job: "Consultor de inovação",
      text: "Entusiasta e defensor das bandeiras do empreendedorismo, da juventude e da educação. É graduando em Engenharia de Produção pela Universidade de São Paulo (USP) e MBA Jr. em Liderança no Programa de Formação em Gestão de Negócios do Business Behavior Institute of Chicago. Atua na Equipe de Consultoria e Inovação da Pieracciani.Em 2021, foi Presidente Executivo da Federação das Empresas Juniores do Estado de São Paulo (FEJESP), onde representou uma rede com cerca de 5.000 jovens membros de 206 Empresas Juniores de 45 Instituições de Ensino Superior e 45 municípios. Neste período, utilizando estratégias de posicionamento institucional diante da esfera pública, de organizações do terceiro setor, de empresas e de universidades, liderou iniciativas de grande impacto, como o Transforme SP, projeto de apoio direto para Micro e Pequenas Empresas, e a promoção de soluções voltadas para a Rede Pública de Ensino e para hospitais e unidades básicas de saúde.Além disso, possui experiência na aplicação de treinamentos sobre gestão, negociação, métodos ágeis, estratégia e marketing para Empresas Juniores.",
    },
    person5: {
      name: "Carol Novaes",
      job: "Consultora de inovação",
      text: "Bacharelanda em Administração pela Pontifícia Universidade Católica de São Paulo, com formação prevista para Dezembro/2023.Premiada em Primeiro Lugar pelo CNPq e Núcleo de Iniciações Científicas da PUC-SP na categoria Melhor Trabalho da FEA-PUC, com projeto de pesquisa na área de Ciências Sociais Aplicadas à Administração, na temática Trabalho e Relações de Gênero.Em continuidade às produções científicas, obteve reconhecimento e convite à publicação de seu Trabalho de Conclusão de Curso, com projeto de pesquisa também na área de Relações de Gênero e Trabalho, relacionando os conhecimentos de Administração, Psicologia e Sociologia, propondo um olhar sociológico sobre a maternidade e a projeção de carreira de mulheres graduadas a luz das teorias de Donald Woods Winnicott e Melanie Klein.Atua em aceleração de startups e projetos de inovação aberta nas áreas de Hidrogênio Verde, ESG, Inovação e Tecnologias Disruptivas.Trabalha também, como autônoma, em Marketing Digital e Estratégias de Negócio para micro e pequenos empreendedores do ramo de confeitaria e gastronomia.",
    },
    person6: {
      name: "Rafael Nunes",
      job: "Consultor de inovação",
      text: "Apaixonado por arte e criação, é um profissional graduado em design pela Pontifícia Universidade Católica de São Paulo (PUC-SP), com ênfase em UX/UI. Sua jornada profissional o levou inicialmente a atuar como designer em outras empresas no setor de marketing e T.I. Antes de mesmo de sua graduação, expandiu seus horizontes ao completar um curso de inglês como segundo idioma pela ILSC, em Melbourne, Austrália, em 2019. Essa experiência não apenas aprimorou sua proficiência no idioma, mas também proporcionou a imersão em uma cultura totalmente nova.Ao ingressar na equipe de Consultoria da Pieracciani, encontrou o ambiente propício para se desenvolver e aprimorar suas habilidades, onde já atuou em diversos projetos, abrangendo setores diversos como: financeiro, logística, materiais plásticos, fabricação de produtos químicos etc.",
    },
  },
  sixthComponent: {
    text1: "Conte também com o nosso",
    text2:
      "Crie um ambiente favorável à Inovação com um Time fortemente engajado e resultados impactantes!",
  },
  seventhComponent: {
    text1: "Agora que você já entendeu como funciona, vamos começar!",
  },
  ebook: {
    text1: "Acesse nosso E-Book!",
  },
};
