import React, { useState, useEffect } from "react";
import "./levelBar.css";
import PaintBrush from "./assets/paintBrush.svg";
import PaintBrushMob from "./assets/paintBrushMob.svg";
import PaintBrushLittle from "./assets/paintBrushLittleMob.svg";

function LevelBar({ name, width, color }) {
  const [devWidth, setDevWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setDevWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styleBar = {
    width: width,
    background: color,
  };
  const styleTextBar = {
    color: color,
  };

  return (
    <>
      {devWidth > 991 && (
        <div className="level-bar">
          <div className="level-bar-title" style={styleTextBar}>
            {name}
          </div>
          <div className="all-bar">
            <div className="background" style={styleBar} />
            <div className="front">
              <img src={PaintBrush} alt="lever-bar" />
            </div>
          </div>
        </div>
      )}
      {devWidth < 991 && (
        <div className="level-bar">
          <div className="level-bar-title" style={styleTextBar}>
            {name}
          </div>
          <div className="all-bar">
            <div className="background" style={styleBar} />
            <div className="front">
              <img
                src={devWidth > 410 ? PaintBrushMob : PaintBrushLittle}
                alt="lever-bar"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default LevelBar;
