import { createContext, useState, useContext } from "react";
import axios from "axios";
import { validate } from "react-email-validator";
import { LoadingContext } from "../../diagnosis/contexts/loadingContext";

const WorkshopContext = createContext();

function WorkshopProvider({ children }) {
  const { setIsLoading } = useContext(LoadingContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [nameIsValid, setNameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  

  async function sendConversionToRdStation() {
    let canNavigate = false;
    setIsLoading(true);
    const identifier = process.env.REACT_APP_RD_IDENTIFIER_WORKSHOP || "";
    const path = process.env.REACT_APP_PATH_RD_STATION || "";
    const apiKey = process.env.REACT_APP_KEY_RD_STATION || "";

    let payload = {
      event_type: "CONVERSION",
      event_family: "CDP",
      payload: {
        conversion_identifier: identifier,
        email: email,
        name: name,
        mobile_phone: phone,
      },
    };

      if (identifier !== "" && path !== "" && apiKey !== "") {
        try {
          await axios.post(`${path}?api_key=${apiKey}`, payload);
          setIsLoading(false);
          canNavigate = true;
        } catch (error) {
          setIsLoading(false);
          alert("Ocorreu algum erro, tente novamente.");
        }
      } else {
        console.log("Faltando envs");
        console.log("payload:", payload);
        setIsLoading(false);
        canNavigate = true;
      }
      return canNavigate;
    }

  function validateFields() {
    let testName = name.split(" ").length >= 2;
    setNameIsValid(testName);
    let testEmail = validate(email);
    setEmailIsValid(testEmail);
    let testPhone = validatePhone(phone);
    setPhoneIsValid(testPhone);
    return (
      testName &&
      testEmail &&
      testPhone
    );
  }

  function validatePhone(phone) {
    const regex = /^(\(\d{2}\) \d{5}-\d{4})?$/;
    return regex.test(phone);
  }

  function validateCanAccessRoute() {
    let testName = name.split(" ").length >= 2;
    let testEmail = validate(email);
    let testPhone = validatePhone(phone);
    return (
      testName &&
      testEmail &&
      testPhone 
    );
  }

  return (
    <WorkshopContext.Provider
      value={{
        setName,
        name,
        setEmail,
        email,
        setPhone,
        phone,
        sendConversionToRdStation,
        nameIsValid,
        emailIsValid,
        phoneIsValid,
        validateFields,
        validateCanAccessRoute,
      }}
    >
      {children}
    </WorkshopContext.Provider>
  );
}

export { WorkshopContext, WorkshopProvider };
