import "./results.css";
import Icon from "../resultLogo/Icon";
import LevelBar from "../resultLevelBar/LevelBar";
import Content from "../resultContent/ResultContent";
function Results({ iconSvg, name, value, title, text, color }) {
  const width = `${(value / 5) * 100}%`;

  return (
    <div className="results">
      <div className="top-results">
        <Icon iconSvg={iconSvg} />
        <div className="level-bar-result">
        <LevelBar name={name} width={width} color={color} />
        </div>
      </div>
      <div className="bottom-results">
        <Content title={title} text={text} color={color} />
      </div>
    </div>
  );
}
export default Results;
