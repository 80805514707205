import './persons.css'
import { lpTexts } from '../../external/lpTexts';
import EachPerson from "../personEach/PersonEach";

function Persons(){
    let p1Texts=lpTexts.fifthComponent.person1;
    let p2Texts=lpTexts.fifthComponent.person2;
    let p3Texts=lpTexts.fifthComponent.person3;
    let p4Texts=lpTexts.fifthComponent.person4;
    let p5Texts=lpTexts.fifthComponent.person5;
    let p6Texts=lpTexts.fifthComponent.person6;
    return(
    <div className='all-persons'>
    <EachPerson imgSvg="https://drive.google.com/thumbnail?id=1PTd8fuPODhu9OqXpGDHbUfsD610pJcX7&sz=w300" name={p1Texts.name} job={p1Texts.job} text={p1Texts.text} photoMargin="-45px" />
    <EachPerson imgSvg="https://drive.google.com/thumbnail?id=1JaXiBSLhCjmuVA1pJfMy_iT28FgBiAYJ&sz=w300" name={p2Texts.name} job={p2Texts.job} text={p2Texts.text}/>
    <EachPerson imgSvg="https://drive.google.com/thumbnail?id=1JE_F5RGyvGGPhXd30KYQ7l1pDhGC90tj&sz=w300" name={p3Texts.name} job={p3Texts.job} text={p3Texts.text} photoMargin="2px"/>
    <EachPerson imgSvg="https://drive.google.com/thumbnail?id=1zIhAK6LW5f9X839gLb4HLXoB5ASxOs9s&sz=w300" name={p4Texts.name} job={p4Texts.job} text={p4Texts.text} photoMargin="-22px" />
    <EachPerson imgSvg="https://drive.google.com/thumbnail?id=1mbWmmN0-8Q31Fd4Les6Zx-7iULOSQjhg&sz=w300" name={p5Texts.name} job={p5Texts.job} text={p5Texts.text} photoMargin="5px" />
    <EachPerson imgSvg="https://drive.google.com/thumbnail?id=1oqOZ2GrwTfNmtbVlnM7UlANCzS6vCwvc&sz=w300" name={p6Texts.name} job={p6Texts.job} text={p6Texts.text} photoMargin="6px" />
    </div>
    )
}
export default Persons;