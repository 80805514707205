import "./checkbox.css";

function Checkbox({ isChecked, textCheckbox, setter, isValid, errorMessage }) {
  const handleCheckboxChange = (event) => {
    setter(event.target.checked);
  };

  return (
    <div className="allCheckbox">
      <label className="checkboxComponent">
        <input
          checked={isChecked}
          type="checkbox"
          id="consentCheckbox"
          className="hiddenCheckbox"
          onChange={handleCheckboxChange}
        />
        <span className="customCheckbox"></span>
        <div className="text">{textCheckbox}</div>
      </label>
      {!isValid && (
        <div className="error">{errorMessage}</div>
      )}
    </div>
  );
}

export default Checkbox;
