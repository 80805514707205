import { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { validate } from "react-email-validator";
import { LoadingContext } from "./loadingContext";

const companyInformationsContext = createContext();

function CompanyInformationsProvider({ children }) {
  const { setIsLoading } = useContext(LoadingContext);

  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contactAuthorization, setContactAuthorization] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [storeData, setStoreData] = useState(false);

  const [nameIsValid, setNameIsValid] = useState(true);
  const [jobTitleIsValid, setJobTitleIsValid] = useState(true);
  const [companyNameIsValid, setCompanyNameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [contactAuthorizationIsValid, setContactAuthorizationIsValid] =
    useState(true);
  const [personalDataIsValid, setPersonalDataIsValid] = useState(true);
  const [storeDataIsValid, setStoreDataIsValid] = useState(true);

  useEffect(() => {
    let companyDataLocalstorage = localStorage.getItem("companyData");
    if (companyDataLocalstorage) {
      let companyJson = JSON.parse(companyDataLocalstorage);
      setName(companyJson["name"]);
      setJobTitle(companyJson["job_title"]);
      setCompanyName(companyJson["company_name"]);
      setEmail(companyJson["email"]);
      setPhone(companyJson["mobile_phone"]);
      setContactAuthorization(companyJson["contact_authorization"]);
      setPersonalData(companyJson["personal_data"]);
      setStoreData(companyJson["store_data"]);
    }
  }, []);

  async function sendConversionToRdStation() {
    let canNavigate = false;
    setIsLoading(true);
    const identifier = process.env.REACT_APP_RD_IDENTIFIER_COMPANY || "";
    const path = process.env.REACT_APP_PATH_RD_STATION || "";
    const apiKey = process.env.REACT_APP_KEY_RD_STATION || "";

    let payload = {
      event_type: "CONVERSION",
      event_family: "CDP",
      payload: {
        conversion_identifier: identifier,
        email: email,
        name: name,
        job_title: jobTitle,
        mobile_phone: phone,
        company_name: companyName,
      },
    };

    if (contactAuthorization) {
      if (identifier !== "" && path !== "" && apiKey !== "") {
        try {
          await axios.post(`${path}?api_key=${apiKey}`, payload);
          setIsLoading(false);
          canNavigate = true;
        } catch (error) {
          setIsLoading(false);
          alert("Ocorreu algum erro, tente novamente.");
        }
      } else {
        console.log("Faltando envs");
        console.log("payload:", payload);
        setIsLoading(false);
        canNavigate = true;
      }
    } else {
      setIsLoading(false);
      alert("Aceite a autorização para receber informações para continuar.");
    }
    return canNavigate;
  }

  async function saveCompanyData() {
    let companyData = {
      name: name,
      job_title: jobTitle,
      company_name: companyName,
      email: email,
      mobile_phone: phone,
      contact_authorization: contactAuthorization,
      personal_data: personalData,
      store_data: storeData,
    };
    localStorage.setItem("companyData", JSON.stringify(companyData));
  }

  function validateFields() {
    let testName = name.split(" ").length >= 2;
    setNameIsValid(testName);
    let testJobTitle = jobTitle !== "";
    setJobTitleIsValid(testJobTitle);
    let testCompanyName = companyName !== "";
    setCompanyNameIsValid(testCompanyName);
    let testEmail = validate(email);
    setEmailIsValid(testEmail);
    let testPhone = validatePhone(phone);
    setPhoneIsValid(testPhone);
    let testContactAuthorization = contactAuthorization === true;
    setContactAuthorizationIsValid(testContactAuthorization);
    let testPersonalData = personalData === true;
    setPersonalDataIsValid(testPersonalData);
    let testStoreData = storeData === true;
    setStoreDataIsValid(testStoreData);
    return (
      testName &&
      testJobTitle &&
      testCompanyName &&
      testEmail &&
      testPhone &&
      testContactAuthorization &&
      testPersonalData &&
      testStoreData
    );
  }

  function validatePhone(phone) {
    const regex = /^(\(\d{2}\) \d{5}-\d{4})?$/;
    return regex.test(phone);
  }

  function validateCanAccessRoute() {
    let testName = name.split(" ").length >= 2;
    let testJobTitle = jobTitle !== "";
    let testCompanyName = companyName !== "";
    let testEmail = validate(email);
    let testPhone = validatePhone(phone);
    let testContactAuthorization = contactAuthorization === true;
    let testPersonalData = personalData === true;
    let testStoreData = storeData === true;

    return (
      testName &&
      testJobTitle &&
      testCompanyName &&
      testEmail &&
      testPhone &&
      testContactAuthorization &&
      testPersonalData &&
      testStoreData
    );
  }

  return (
    <companyInformationsContext.Provider
      value={{
        setName,
        name,
        setJobTitle,
        jobTitle,
        setCompanyName,
        companyName,
        setEmail,
        email,
        setPhone,
        phone,
        setContactAuthorization,
        contactAuthorization,
        setPersonalData,
        personalData,
        setStoreData,
        storeData,
        sendConversionToRdStation,
        saveCompanyData,
        nameIsValid,
        jobTitleIsValid,
        emailIsValid,
        companyNameIsValid,
        phoneIsValid,
        contactAuthorizationIsValid,
        personalDataIsValid,
        storeDataIsValid,
        validateFields,
        validateCanAccessRoute,
      }}
    >
      {children}
    </companyInformationsContext.Provider>
  );
}

export { companyInformationsContext, CompanyInformationsProvider };
