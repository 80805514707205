import "./thirdComponent.css";
import GeomtricImg from "./assets/geometricForms.svg";
import LeftImg from "./assets/leftImage.svg";
import LeftImgMobile from "./assets/leftImageMobile.svg"
import RightImgMobile from "./assets/rightImageMobile.svg"
import RightImg from "./assets/rightImage.svg";
import ButtonDiagnosis from "../buttonDiagnosis/ButtonDiagnosis";
import { lpTexts } from "../../external/lpTexts";
import { useNavigate } from "react-router-dom";
function ThirdComponent() {
  const navigate = useNavigate();
  let texts = lpTexts.thirdComponent;
  const devWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return (
    <div className="all-tc">
      <div className="tc-top-text-title">{texts.text1}</div>
      <div className={devWidth > 991 ? "tc-board" : "tc-board-disabled"}>
        <div className="tc-geometry">
          <img src={GeomtricImg} alt="" />
        </div>
        <div className="tc-all-content">
          <div className="tc-all-left">
            <div className="tc-left-image">
              <img src={LeftImg} alt="" />
            </div>
            <div className="tc-left-title">{texts.text2}</div>
            <div className="tc-left-text">{texts.text3}</div>
          </div>
          <div className="tc-all-right">
            <div className="tc-right-image">
              <img src={RightImg} alt="" />
            </div>
            <div className="tc-right-title">{texts.text4}</div>
            <div className="tc-right-text">{texts.text5}</div>
          </div>
        </div>
      </div>
      
      <div className={devWidth > 991  ? "tc-board-mobile-disabled" : "tc-board-mobile"}>
        <div className="tc-all-content">
          <div className="tc-all-top">
            <div className="tc-top-image">
              <img src={LeftImgMobile} alt="" />
            </div>
            <div className="tc-top-title">{texts.text2}</div>
            </div>
            <div className="tc-top-text">{texts.text3}</div>
        </div>
      </div>
      <div className={devWidth > 991 ? "tc-board-mobile-disabled" : "tc-board-mobile"}>
      <div className="tc-all-content">
          <div className="tc-all-bottom">
            <div className="tc-bottom-image">
              <img src={RightImgMobile} alt="" />
            </div>
            <div className="tc-bottom-title">{texts.text4}</div>
            </div>
            <div className="tc-bottom-text">{texts.text5}</div>
        </div>
      </div>
      <div
        onClick={() => {
          navigate("/diagnostico");
          window.scrollTo({ top: 0 });
        }}
      >
        <ButtonDiagnosis buttonText="Preencher diagnóstico" />
      </div>
    </div>
  );
}
export default ThirdComponent;
