import "./ebook.css";
import EbookImg from "./assets/ebookImg.svg";
import { lpTexts } from "../../external/lpTexts";
function Ebook() {
  let texts = lpTexts.ebook;
  const ebookPath = process.env.REACT_APP_PATH_EBOOK || "";

  if (ebookPath !== "") {
    return (
      <a
        className="all-ebook"
        href={ebookPath}
        target="_blank"
        rel="noreferrer"
      >
        <div className="eb-circle">
          <div className="eb-img">
            <img src={EbookImg} alt="" />
          </div>
        </div>
        <div className="eb-text">{texts.text1}</div>
      </a>
    );
  }
  return <></>;
}
export default Ebook;
