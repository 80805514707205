import { Route, Routes } from "react-router-dom";
import InitialPage from "../pages/initialPage/InitialPage.jsx";

function LpRoutes() {
  return (
    <Routes>
      <Route path="/" element={<InitialPage />} />
    </Routes>
  );
}

export default LpRoutes;
