import "./loadingSpinner.css";
function LoadingSpinner() {
  return (
    <div id="loading" class="loading">
      <div class="spinner"></div>
    </div>
  );
}

export default LoadingSpinner;
