import "./steps.css";

function Steps({ stepNumber, stepImg, stepTitle, stepText, width, widthMob }) {
  const devWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let styleText = {};
  if (devWidth > 991) {
    styleText = {
      width: width,
    };
  } else {
    styleText = {
      width: widthMob,
    };
  }

  return (
    <div className="all-step">
      <div className="step-ball">
        <div className="step-number">{stepNumber}</div>
      </div>
      <div className="step-img">
        <img src={stepImg} alt="" />
      </div>
      <div className="step-title">{stepTitle}</div>
      <div className="step-text" style={styleText}>
        {stepText}
      </div>
    </div>
  );
}
export default Steps;
