import React, { useState } from "react";
import "./errorToast.css";
import alertIcon from "./assets/alertIcon.svg";
import closeIcon from "./assets/closeIcon.svg";

function ErrorToast({ message }) {
  const [showToast, setShowToast] = useState(true);

  const handleClose = () => {
    setShowToast(false);
  };

  return (
    <>
      {showToast && (
        <div className="error-toast">
          <img src={alertIcon} alt="alert icon" />
          <span className="error-toast-message">{message}</span>
          <div className="close-button" onClick={handleClose}>
            <img src={closeIcon} alt="close icon" />
          </div>
        </div>
      )}
    </>
  );
}

export default ErrorToast;
