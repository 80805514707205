import { useContext } from "react";
import Scaffold from "../../../shared/scaffold/scaffold";
import Button from "../../components/button/Button";
import Header from "../../components/header/Header";
import Field from "../../components/field/Field";
import FieldJob from "../../components/fieldJob/FieldJob";
import Checkbox from "../../components/checkbox/checkbox";
import "./companyFormPage.css";
import { companyInformationsContext } from "../../contexts/companyInformationsContext";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { LoadingContext } from "../../contexts/loadingContext";
import { useNavigate } from "react-router-dom";

function CompanyFormPage() {
  const {
    setName,
    name,
    setJobTitle,
    jobTitle,
    setCompanyName,
    companyName,
    setEmail,
    email,
    setPhone,
    phone,
    setContactAuthorization,
    contactAuthorization,
    setPersonalData,
    personalData,
    setStoreData,
    storeData,
    sendConversionToRdStation,
    saveCompanyData,
    nameIsValid,
    jobTitleIsValid,
    emailIsValid,
    companyNameIsValid,
    phoneIsValid,
    contactAuthorizationIsValid,
    personalDataIsValid,
    storeDataIsValid,
    validateFields,
  } = useContext(companyInformationsContext);

  const navigate = useNavigate();

  const { isLoading } = useContext(LoadingContext);

  return (
    <Scaffold>
      <Header />
      <section className="form">
        <Field
          inputValue={name}
          inputTitle={"Nome completo"}
          setter={setName}
          isValid={nameIsValid}
          errorMessage={"Deve conter ao menos um nome e um sobrenome"}
        />
        <FieldJob
          inputValue={jobTitle}
          inputTitle={"Cargo"}
          setter={setJobTitle}
          isValid={jobTitleIsValid}
          errorMessage={"Selecione um cargo"}
        />
        <Field
          inputValue={companyName}
          inputTitle={"Empresa"}
          setter={setCompanyName}
          isValid={companyNameIsValid}
          errorMessage={"Insira o nome da empresa"}
        />
        <Field
          inputValue={email}
          inputTitle={"E-mail"}
          setter={setEmail}
          isValid={emailIsValid}
          errorMessage={"E-mail inválido"}
        />
        <Field
          inputValue={phone}
          inputTitle={"Celular (opcional)"}
          mask={"(99) 99999-9999"}
          setter={setPhone}
          isValid={phoneIsValid}
          errorMessage={"Número inválido"}
        />
      </section>
      <div className="checkboxContainer">
        <div className="checkbox">
          <Checkbox
            isChecked={contactAuthorization}
            textCheckbox={
              "O titular aceita receber informações e noticias relacionadas ao tema do presente projeto via e-mail e/ou outras fontes de envio de informações."
            }
            setter={setContactAuthorization}
            isValid={contactAuthorizationIsValid}
            errorMessage={"Esta opção precisa ser marcada para seguir"}
          />
        </div>
        <div className="checkbox">
          <Checkbox
            isChecked={personalData}
            textCheckbox={
              "O titular consente e concorda que ao fornecer seus dados pessoais a Pieracciani configura como registro a manifestação livre, informada e inequívoca pela qual o Titular concorda com o tratamento de seus dados pessoais para a finalidade do projeto DIAGNÓSTICO DE CULTURA, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD)."
            }
            setter={setPersonalData}
            isValid={personalDataIsValid}
            errorMessage={"Esta opção precisa ser marcada para seguir"}
          />
        </div>
        <div className="checkbox">
          <Checkbox
            isChecked={storeData}
            textCheckbox={
              "O titular aceita e consente que seus dados serão objeto de armazenamento com a finalidade do projeto DIAGNÓSTICO DE CULTURA."
            }
            setter={setStoreData}
            isValid={storeDataIsValid}
            errorMessage={"Esta opção precisa ser marcada para seguir"}
          />
        </div>
      </div>
      <div
        className="buttonContainer"
        onClick={async () => {
          if (validateFields()) {
            let canNavigate = await sendConversionToRdStation();
            saveCompanyData();
            if (canNavigate) {
              navigate("/diagnostico/entenda-como-funciona");
            }
          }
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <Button textButton={"Preencher diagnóstico"} />
      </div>
      <div
        className="back-button"
        onClick={() => {
          navigate("/");
          window.scrollTo({ top: 0 });
        }}
      >
        &lt; Voltar
      </div>
      {isLoading && <LoadingSpinner />}
    </Scaffold>
  );
}

export default CompanyFormPage;
