import "./levelBar_2.css";
import PaintBrushDetails from "./assets/paintBrushDetails.svg";
function LevelBar({ name, width, color }) {
  const styleBar = {
    width: width,
    background: color,
  };
  const styleTextBar = {
    color: color,
  };
  return (
    <div className="level-bar-details">
      <div className="level-bar-title-details" style={styleTextBar}>{name}</div>
      <div className="all-bar-details">
        <div className="background-details" style={styleBar} />
        <div className="front-details">
          <img src={PaintBrushDetails} alt="lever-bar-details" />
        </div>
      </div>
    </div>
  );
}
export default LevelBar;
