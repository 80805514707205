import "./button.css";
function Button({ textButton, isBig }) {
  return (
    <button className={isBig ? "big-button" : "small-button"} type="button">
      {textButton}
    </button>
  );
}

export default Button;
