import "./secondComponent.css";
import ColumnImg from "./assets/column.svg";
import ScultureImg from "./assets/sculture.svg";
import ColumnImgMobile from "./assets/columnMobile.svg"
import ScultureImgMobile from "./assets/scultureMobile.svg"
import { lpTexts } from "../../external/lpTexts";
function secondComponent() {
  let texts = lpTexts.secondComponent;
  const devWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return (
    <div className="all-second">
      <div className="second-left">
        <div className="column-img">
          <img src={devWidth > 991 ? ColumnImg : ColumnImgMobile} alt="" />
        </div>
        <div className="second-left-texts">
          <div className="second-left-title">{texts.text1}</div>
          <div className="second-left-li">
            <ul>
              <li>{texts.text2}</li>
              <li>{texts.text3}</li>
              <li>{texts.text4}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={devWidth > 991 ? "second-right" : "second-right-disabled"}>
        <div className="sculture-img">
          <img src={ScultureImg} alt="" />
        </div>
        <div className="second-right-texts">
          <div className="second-right-title">{texts.text5}</div>
          <div className="second-right-li">
            <ul>
              <li>{texts.text6}</li>
              <li>{texts.text7}</li>
              <li>{texts.text8}</li>
              <li>{texts.text9}</li>
              <li>
                {texts.text10
                  .split("%")
                  .map((part, index) =>
                    index % 2 === 1 ? <strong key={index}>{part}</strong> : part
                  )}
              </li>
            </ul>
            <div className="second-asterisk">{texts.text11}</div>
          </div>
        </div>
        </div>
        <div className={devWidth > 991 ? "second-right-mobile-disabled" : "second-right-mobile"}>
        <div className="second-right-texts">
          <div className="second-right-title">{texts.text5}</div>
          <div className="second-right-li">
            <ul>
              <li>{texts.text6}</li>
              <li>{texts.text7}</li>
              <li>{texts.text8}</li>
              <li>{texts.text9}</li>
              <li>
                {texts.text10
                  .split("%")
                  .map((part, index) =>
                    index % 2 === 1 ? <strong key={index}>{part}</strong> : part
                  )}
              </li>
            </ul>
            <div className="second-asterisk">{texts.text11}</div>
          </div>
        </div>
        <div className="sculture-img">
          <img src={ScultureImgMobile} alt="" />
        </div>
      </div>
    </div>
  );
}
export default secondComponent;
