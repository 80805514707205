import "./questionOption.css";

function QuestionOption({ optionText, checked, onChange }) {
  const className = `border ${checked ? "checked" : ""}`;
  return (
    <div className="question-option" onClick={() => onChange()}>
      <div className={className}>
        <div className="button">
          <input
            className="question-option-input"
            type="radio"
            value={optionText}
            checked={checked}
            onChange={onChange}
          />
        </div>
        <label className="question-option-text">{optionText}</label>
      </div>
    </div>
  );
}

export default QuestionOption;
