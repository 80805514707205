import "./resultsDetails.css";
import LevelBar2 from "../resultLevelBar/LevelBar_2";
import ResultContent from "../resultContent/ResultContent";

function ResultDetails({ name, value, title, text, color }) {
  const width = `${(value / 5) * 100}%`;

  return (
    <div className="result-details">
      <div className="top-result-details">
        <LevelBar2 name={name} width={width} color={color} />
      </div>
      <div className="bottom-result-details">
        <ResultContent title={title} text={text} color={color} />
      </div>
    </div>
  );
}
export default ResultDetails;
