import "./fieldJob.css";
import React, { useState, useRef, useEffect } from "react";

function FieldJob({ inputValue, inputTitle, setter, isValid, errorMessage }) {
  const dropdownRef = useRef(null);
  const handleInputChange = async (option) => {
    setter(option);
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleSelectFocus = () => {
    setIsClicked(true);
  };
  const handleSelectBlur = () => {
    setIsClicked(false);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleSelectBlur();
      }
    }

    // Adiciona o evento de clique ao montar o componente
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove o evento de clique ao desmontar o componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const devWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  return (
    <>
    {devWidth > 991 && (
    <div className="allCargos" ref={dropdownRef}>
      <div className="cargos">{inputTitle}:</div>
      <div
        className={`job-input ${
          !isValid && !isClicked ? "job-input-invalid" : ""
        } ${isClicked ? "job-input-selected" : ""}`}
        onClick={handleSelectFocus}
      >
        <div className="value">{inputValue==="" ? "Selecione sua ocupação" : inputValue}</div>
      </div>

      <div className={`dropdown ${isClicked ? "show-dropdown" : ""}`}>
        <div className="list-dropdown">
          {options.map((option) => (
            <div
              key={option}
              className="options"
              onClick={async () => {
                await handleInputChange(option);
                handleSelectBlur();
              }}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
      {!isValid && <div className="error">{errorMessage}</div>}
    </div>
    )}
     {devWidth < 991 && (
      <div className="allCargos-mob" ref={dropdownRef}>
      <div className="cargos-mob">{inputTitle}:</div>
      <div
        className={`job-input-mob ${
          !isValid && !isClicked ? "job-input-invalid-mob" : ""
        } ${isClicked ? "job-input-selected-mob" : ""}`}
        onClick={handleSelectFocus}
      >
        <div className="value-mob">{inputValue==="" ? "Selecione sua ocupação" : inputValue}</div>
      </div>

      <div className={`dropdown-mob ${isClicked ? "show-dropdown-mob" : ""}`}>
        <div className="list-dropdown-mob">
          {options.map((option) => (
            <div
              key={option}
              className="options-mob"
              onClick={async () => {
                await handleInputChange(option);
                handleSelectBlur();
              }}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
      {!isValid && <div className="error-mob">{errorMessage}</div>}
    </div>
     )}
     </>
  );
}

const options = [
  "Membro do conselho",
  "Inteligência de negócios",
  "Transformação de negócios",
  "Chefe de gabinete",
  "Consultor",
  "Designer",
  "Diretor/Líder de Departamento",
  "Engenheiro",
  "Desenvolvedor",
  "Executivo",
  "Recursos humanos",
  "Inovação",
  "Aprendizagem e Desenvolvimento (L&D)",
  "Gerente/Líder de equipe",
  "Operações",
  "Relações públicas/Comunicações",
  "Compras",
  "Gerente/proprietário de produto",
  "Gerente de produto/proprietário",
  "Professor/Instrutor",
  "Gerente de programa/projeto",
  "Recrutador",
  "Pesquisador",
  "Engenheiro de vendas/soluções",
  "Capacitação de vendas",
  "Representante de vendas",
  "Scrum Master/Agile Coach",
  "Especialista",
  "Estrategista",
  "Estudante",
  "Administrador do sistema",
  "Treinador/Coach",
  "UX",
  "Escritor/Editor",
  "Outro",
];
export default FieldJob;
