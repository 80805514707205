import "./footer.css";
import FooterLogo from "./assets/footerLogo.svg";
import FooterLogoMobile from "./assets/footerLogoMobile.svg";
import YTLogo from "./assets/youtubeLogo.svg";
import YTLogoMobile from "./assets/youtubeLogoMobile.svg";
import FaceLogo from "./assets/facebookLogo.svg";
import FaceLogoMobile from "./assets/facebookLogoMobile.svg";
import InstaLogo from "./assets/instaLogo.svg";
import InstaLogoMobile from "./assets/instaLogoMobile.svg";
import LinkedinLogo from "./assets/linkedinLogo.svg";
import LinkedinLogoMobile from "./assets/linkedinLogoMobile.svg";
function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="logo">
          <img
            src={FooterLogo}
            alt="Logo"
          />
        </div>
        <div className="endereco">
          Av. Brigadeiro Faria Lima 1903 cj. 112
          <br />
          Jd. Paulistano | São Paulo - SP
        </div>
        <div className="contato">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:contato@pieracciani.com.br"
          >
            contato@pieracciani.com.br
          </a>
        </div>
        <div className="redes">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://br.linkedin.com/company/pieracciani"
          >
            <img
              src={LinkedinLogo}
              alt="Linkedin"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/pieracciani/"
          >
            <img
              src={FaceLogo}
              alt="Facebook"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/pieraccianioficial/"
          >
            <img
              src={InstaLogo}
              alt="Instagram"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/@Pieracciani"
          >
            <img src={YTLogo} alt="Youtube" />
          </a>
        </div>
      </footer>
      <footer className="footer-mobile">
        <div className="top-footer">
        <div className="logo">
          <img
            src={FooterLogoMobile}
            alt="Logo"
          />
        </div>
        <div className="endereco">
          Av. Brigadeiro Faria Lima 1903 cj. 112
          <br />
          Jd. Paulistano | São Paulo - SP
        </div>
        </div>
        <div className="bottom-footer">
        <div className="contato">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:contato@pieracciani.com.br"
          >
            contato@pieracciani.com.br
          </a>
        </div>
        <div className="redes">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://br.linkedin.com/company/pieracciani"
          >
            <img
              src={LinkedinLogoMobile}
              alt="Linkedin"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/pieracciani/"
          >
            <img
              src={FaceLogoMobile}
              alt="Facebook"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/pieraccianioficial/"
          >
            <img
              src={InstaLogoMobile}
              alt="Instagram"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/@Pieracciani"
          >
            <img src={YTLogoMobile} alt="Youtube" />
          </a>
        </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
