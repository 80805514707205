import { Route, Routes } from "react-router-dom";
import ErrorPage from "../../shared/errorPage/ErrorPage.jsx";
import CompanyFormPage from "../pages/companyFormPage/CompanyFormPage.jsx";
import HowWorksPage from "../pages/howWorksPage/HowWorksPage.jsx";
import QuestionPage from "../pages/questionPage/QuestionPage.jsx";
import ResultsPage from "../pages/resultsPage/ResultsPage.jsx";
import { useContext } from "react";
import { companyInformationsContext } from "../contexts/companyInformationsContext.js";
import { QuestionsContext } from "../contexts/questionsContext.js";

function DiagnosisRoutes() {
  const { validateCanAccessRoute } = useContext(companyInformationsContext);
  const { validateAnswers } = useContext(QuestionsContext);

  return (
    <Routes>
      <Route path="/" element={<CompanyFormPage />} />
      {validateCanAccessRoute() && (
        <Route path="entenda-como-funciona" element={<HowWorksPage />} />
      )}
      {validateCanAccessRoute() && (
        <Route path="perguntas" element={<QuestionPage />} />
      )}
      {validateAnswers() && (
        <Route path="resultado" element={<ResultsPage />} />
      )}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default DiagnosisRoutes;
