import './pillars.css'
function pillars({ name, iconSVG }) {
  return (
    <div className="all-pillars">
      <div className="icon">
        <img src={iconSVG} alt="icon" />
      </div>
      <div className="pillar-name">{name}</div>
    </div>
  );
}
export default pillars;