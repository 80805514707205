import { useContext } from "react";
import Scaffold from "../../../shared/scaffold/scaffold";
import TopWorkshop from "../../components/topWorkshop/TopWorkshop";
import Fields from "../../components/fields/Fields";
import "./workshopPage.css";
import { LoadingContext } from "../../../diagnosis/contexts/loadingContext";
import LoadingSpinner from "../../../diagnosis/components/loadingSpinner/LoadingSpinner";
import { workshopTexts } from "../../external/workshopTexts";
import { WorkshopContext } from "../../context/workshopContext";
import { useNavigate } from "react-router-dom";
function WorkshopPage() {
  const navigate = useNavigate();
  const {
    setName,
    name,
    setEmail,
    email,
    setPhone,
    phone,
    sendConversionToRdStation,
    nameIsValid,
    emailIsValid,
    phoneIsValid,
    validateFields,
  } = useContext(WorkshopContext);
  const { isLoading } = useContext(LoadingContext);
  return (
    <Scaffold navbarText="Workshop “Cultura para Inovação”">
      <TopWorkshop />
      <div className="form-ws">
        <div className="form-ws-title" id="scroll-div">
          {workshopTexts.formTitle.title}
        </div>
        <div className="fields">
          <Fields
            inputTitle="Nome Completo"
            inputValue={name}
            setter={setName}
            isValid={nameIsValid}
            errorMessage={"Deve conter ao menos um nome e um sobrenome"}
          />
          <Fields
            inputTitle="E-mail"
            inputValue={email}
            setter={setEmail}
            isValid={emailIsValid}
            errorMessage={"E-mail inválido"}
          />
          <Fields
            inputTitle="Celular (opcional)"
            mask={"(99) 99999-9999"}
            inputValue={phone}
            setter={setPhone}
            isValid={phoneIsValid}
            errorMessage={"Número inválido"}
          />
        </div>
        <div
          className="ws-send"
          onClick={async () => {
            if (validateFields()) {
              let canNavigate = await sendConversionToRdStation();
              if (canNavigate) {
                navigate("/");
                window.scrollTo({ top: 0 });
              }
            }
          }}
        >
          <button className="ws-send-button">Enviar</button>
        </div>
        <div
          className="back-button-ws"
          onClick={() => {
            navigate("/");
            window.scrollTo({ top: 0 });
          }}
        >
          &lt; Voltar
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </Scaffold>
  );
}
export default WorkshopPage;
