import "./graphic.css";
import React from "react";
import { RadarChart, PolarRadiusAxis, Radar, PolarGrid } from "recharts";

function RadarGraph({ valueInovationTeams, valueOpenMinded, valuePioneering, title, resume }) {
  const data = [
    { name: "", value: valueInovationTeams, fullMark: 5 },
    { name: "", value: valueOpenMinded, fullMark: 5 },
    { name: "", value: valuePioneering, fullMark: 5 },
  ];
  const devWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  return (
    <>
    {devWidth >= 1220 && (
    <div className="all-graph">
      <RadarChart
        cx={250}
        cy={250}
        outerRadius={150}
        width={490}
        height={450}
        data={data}
      >
        <PolarGrid />
        <PolarRadiusAxis angle={90} domain={[0, 5]} tickCount={6} />
        <Radar
          dataKey="value"
          stroke="#211A60"
          strokeWidth={4}
          fill="#397ebf"
          fillOpacity={0.3}
        />
      </RadarChart>
      <div className="titles">
        <div className="top-inovation-teams">
          <div className="inovation-teams">Times Inovadores</div>
        </div>
        <div className="left-pioneering">
          <div className="pioneering">Pioneirismo</div>
        </div>
        <div className="right-open-minded">
          <p className="open-minded">Mente Aberta</p>
        </div>
      </div>
      <div className="all-resume">
        <div className="resume-title">{title}</div>
        <div className="resume">{resume}</div>
      </div>
    </div> 
    )}

    {devWidth <= 1219 && (
    <div className="all-graph">
      <div className="graph-graph">
      <RadarChart
       cx={devWidth/2}
        outerRadius={125}
        width={devWidth}
        height={400}
        data={data}
      >
        <PolarGrid />
        <PolarRadiusAxis angle={90} domain={[0, 5]} tickCount={6} />
        <Radar
          dataKey="value"
          stroke="#211A60"
          strokeWidth={4}
          fill="#397ebf"
          fillOpacity={0.3}
        />
      </RadarChart>
      <div className="titles">
      <div className="left-pioneering">
          <div className="pioneering">Pioneirismo</div>
          </div>
        <div className="top-inovation-teams">
          <div className="inovation-teams">Times Inovadores</div>
        </div>
        <div className="right-open-minded">
          <p className="open-minded">Mente Aberta</p>
        </div>
      </div>
      </div>
      <div className="all-resume">
        <div className="resume-title">{title}</div>
        <div className="resume">{resume}</div>
      </div>
    </div> )}
    </>
  );
}

export default RadarGraph;
