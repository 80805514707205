import "./personPhoto.css";

function PersonPhoto({ imgSvg, width, height, photoMargin }) {
  const styleSize = {
    width: width,
    height: height,
  };
   const styleEachPhoto = {
    marginTop: photoMargin
   }
  return (
    <div className="all-ball-img"  style={styleSize}>
      <div className="ep-ball-img">
        <div className="ep-only-img" style={styleEachPhoto}>
          <img src={imgSvg} alt="" />
        </div>
      </div>
    </div>
  );
}
export default PersonPhoto;
