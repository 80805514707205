import "./resultsWorkshop.css";
import WorkshopText from "../../../shared/workshop/WorkshopText";
import KnowMoreButton from "../../../shared/workshop/KnowMoreButton";
import ValterImg from "../../../shared/workshop/ValterImg";
function ResultsWorkshop() {
  const devWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  return (
    <>
     {devWidth > 991 && (
    <div className="all-rworkshop">
      <div className="rworkshop-left">
        <div className="rworkshop-content">
          <div className="rworkshop-first-text">
            Dissemine os conceitos deste diagnóstico e potencialize os
            resultados da sua empresa com o nosso
          </div>
          <div className="rworkshop-second-text">
            <WorkshopText color="#2f4b7b"/>
          </div>
          <div className="rworkshop-button">
            <KnowMoreButton />
          </div>
        </div>
      </div>
      <div className="rworkshop-right">
        <div className="rworkshop-img">
          <ValterImg />
        </div>
      </div>
    </div> )}
    {devWidth < 991 && (
      <div className="all-rwp-mob">
        <div className="rwp-mob-left">
        <div className="rwp-mob-text1">
            Dissemine os conceitos deste diagnóstico e potencialize os
            resultados da sua empresa com o nosso
          </div>
          <div className="rwp-mob-text2">
            <WorkshopText color="#2f4b7b" />
          </div>
          <div className="rwp-button">
            <KnowMoreButton />
          </div>
        </div>
        <div className="rwp-mob-img">
          <ValterImg />
        </div>
      </div>
    )}
    </>
  );
}
export default ResultsWorkshop;
