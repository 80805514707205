import { useContext } from "react";
import Scaffold from "../../../shared/scaffold/scaffold";
import PillarHeader from "../../components/pillarHeader/PillarHeader";
import QuestionComponent from "../../components/questionComponent/QuestionComponent";
import { QuestionsContext } from "../../contexts/questionsContext";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../../components/errorToast/ErrorToast";
import AnswerProgressBar from "../../components/answerProgressBar/AnswerProgressBar";

function QuestionPage() {
  const navigate = useNavigate();
  const {
    questionsData,
    nextPillar,
    popPillar,
    actualPillar,
    pillars,
    generateResults,
    validateAnswers,
    questionsAnswered,
    setClickedToFinish,
    clickedToFinish,
    validatePillarAnswers,
    getProgress,
  } = useContext(QuestionsContext);
  console.log(questionsData.pillarName);
  if (questionsData !== undefined) {
    return (
      <Scaffold>
        <PillarHeader pillar={questionsData.pillarName} />
        {questionsData.questions.map((question, index) => (
          <QuestionComponent
            key={index}
            isValid={questionsAnswered.includes(`${actualPillar}-${index}`)}
            question={question}
            pillar={questionsData.pillarName}
            questionId={index}
          />
        ))}
        <div
          onClick={() => {
            if (pillars.indexOf(actualPillar) < 2) {
              if (validatePillarAnswers()) {
                setClickedToFinish(false);
                nextPillar();
              } else {
                setClickedToFinish(true);
              }
            } else {
              if (validateAnswers()) {
                generateResults();
                navigate("/diagnostico/resultado");
              } else {
                setClickedToFinish(true);
              }
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <Button
            textButton={
              pillars.indexOf(actualPillar) < 2 ? "Próximo pilar" : "Finalizar"
            }
            isBig={true}
          />
        </div>

        <p
          onClick={() => {
            if (pillars.indexOf(actualPillar) > 0) {
              popPillar();
            } else {
              navigate("/diagnostico/entenda-como-funciona");
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          style={{
            color: "#000",
            fontFamily: "Flama Condensed",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",

            cursor: "pointer",
          }}
        >
          {"< Voltar"}
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AnswerProgressBar progressPercentage={getProgress()} />
        </div>

        {!validateAnswers() && clickedToFinish && (
          <ErrorToast message="Você não respondeu todas as perguntas" />
        )}
        <div style={{ marginBottom: "120px" }}></div>
      </Scaffold>
    );
  } else {
    <div></div>;
  }
}

export default QuestionPage;
