import "./resultContent.css";

function ResultContent({ color, title, text }) {
  const styleFullContent = {
    borderColor: color,
  };
  const styleTitleContent = {
    backgroundColor: color,
  };
  const styleContent = {
    color: color,
  };
  return (
    <div className="full-content" style={styleFullContent}>
      <div className="title-content" style={styleTitleContent}>
        {title}
      </div>
      <div className="content" style={styleContent}>
        {text}
      </div>
    </div>
  );
}
export default ResultContent;
