import "./howWorksPage.css";
import { howWorksPageTexts } from "../../external/howWorksPageTexts";
import { useNavigate } from "react-router-dom";
import Scaffold from "../../../shared/scaffold/scaffold";
import Pillars from "../../components/howWorksPillars/HowWorksPillars";
import PioneeringIcon from "../../components/howWorksPillars/assets/pioneeringIcon.svg";
import OpenMindedIcon from "../../components/howWorksPillars/assets/OpenMinded.svg";
import InnovationTeamsIcon from "../../components/howWorksPillars/assets/InnovationTeams.svg";
import ShellImg from "./assets/shell.svg";
function HowWorksPage() {
  const navigate = useNavigate();
  let texts = howWorksPageTexts;
  const devWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  return (
    <Scaffold>
      {devWidth > 991 && (
        <>
      <div className="top-hwp-block">
        <div className="hwp-top-text-title">{texts.toptext.title}</div>
        <div className="hwp-top-text-text1">
          {texts.toptext.text1
            .split("%")
            .map((part, index) =>
              index % 2 === 1 ? <b key={index}>{part}</b> : part
            )}
        </div>
        <div className="hwp-top-text-text2">
          {texts.toptext.text2
            .split("%")
            .map((part, index) =>
              index % 2 === 1 ? <b key={index}>{part}</b> : part
            )}
        </div>
      </div>

      <div className="all-pillars-hwp">
        <Pillars
          color="#2f4b7b"
          imgSvg={PioneeringIcon}
          title={texts.pillars.first.title}
          subtitle={texts.pillars.first.subTitle}
          text={texts.pillars.first.text}
        />
        <Pillars
          color="#6797b3"
          imgSvg={InnovationTeamsIcon}
          title={texts.pillars.second.title}
          subtitle={texts.pillars.second.subTitle}
          text={texts.pillars.second.text}
        />
        <Pillars
          color="#78797d"
          imgSvg={OpenMindedIcon}
          title={texts.pillars.third.title}
          subtitle={texts.pillars.third.subTitle}
          text={texts.pillars.third.text}
        />
      </div>
        <div className="bottom-hwp-center">
          <div className="bottom-hwp-text">{texts.bottomText.text}</div>
          <div
            className="bottom-hwp-button"
            onClick={() => {
              navigate("/diagnostico/perguntas");
              window.scrollTo({ top: 0 });
            }}
          >
            <button className="bottom-hwp-button-button">Preencher diagnóstico</button>
          </div>
          <div className="bottom-hwp-back" onClick={() => {
              navigate("/diagnostico");
              window.scrollTo({ top: 0 });
            }}>&lt;Voltar</div>
        </div>
        <div className="bottom-hwp-right">
          <img src={ShellImg} alt="" />
      </div>
      </>
      )}
       {devWidth < 991 && (
        <>
        <div className="top-hwp-block-mob">
        <div className="hwp-top-text-title-mob">{texts.toptext.title}</div>
        <div className="hwp-top-text-text1-mob">
          {texts.toptext.text1
            .split("%")
            .map((part, index) =>
              index % 2 === 1 ? <b key={index}>{part}</b> : part
            )}
        </div>
        <div className="hwp-top-text-text2-mob">
          {texts.toptext.text2
            .split("%")
            .map((part, index) =>
              index % 2 === 1 ? <b key={index}>{part}</b> : part
            )}
        </div>
      </div>
      <div className="all-pillars-hwp-mob">
        <Pillars
          color="#2f4b7b"
          imgSvg={PioneeringIcon}
          title={texts.pillars.first.title}
          subtitle={texts.pillars.first.subTitle}
          text={texts.pillars.first.text}
        />
        <Pillars
          color="#6797b3"
          imgSvg={InnovationTeamsIcon}
          title={texts.pillars.second.title}
          subtitle={texts.pillars.second.subTitle}
          text={texts.pillars.second.text}
        />
        <Pillars
          color="#78797d"
          imgSvg={OpenMindedIcon}
          title={texts.pillars.third.title}
          subtitle={texts.pillars.third.subTitle}
          text={texts.pillars.third.text}
        />
      </div>
      <div className="bottom-hwp-center-mob">
          <div className="bottom-hwp-text-mob">{texts.bottomText.text}</div>
          <div
            className="bottom-hwp-button-mob"
            onClick={() => {
              navigate("/diagnostico/perguntas");
              window.scrollTo({ top: 0 });
            }}
          >
            <button className="bottom-hwp-button-button-mob">Preencher diagnóstico</button>
          </div>
          <div className="bottom-hwp-back" onClick={() => {
              navigate("/diagnostico");
              window.scrollTo({ top: 0 });
            }}>&lt;Voltar</div>
        </div>
        </>
       )}
    </Scaffold>
  );
}
export default HowWorksPage;
