import "./seventhComponent.css";
import ScultureImg from "./assets/sculture.svg";
import { lpTexts } from "../../external/lpTexts";
import { useNavigate } from "react-router-dom";
function SeventhComponent() {
  const navigate = useNavigate();
  let texts = lpTexts.seventhComponent;
  return (
    <div className="all-svc">
      <div className="left-svc">
        <img src={ScultureImg} alt="" />
      </div>
      <div className="svc-right">
        <div className="svc-text">{texts.text1}</div>
        <div
          className="svc-button"
          onClick={() => {
            navigate("/diagnostico");
            window.scrollTo({ top: 0 });
          }}
        >
          <button className="svc-button-button">Preencher questionário</button>
        </div>
      </div>
    </div>
  );
}
export default SeventhComponent;
