import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./fonts.css";
import DiagnosisModule from "./modules/diagnosis/DiagnosisModule";
import LpModule from "./modules/lp/LpModule";
import WorkshopModule from "./modules/workshop/WorkshopModule";
import ErrorPage from "./modules/shared/errorPage/ErrorPage.jsx";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LpModule />} />
          <Route path="/workshop" element={<WorkshopModule />} />
          <Route path="/diagnostico/*" element={<DiagnosisModule />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
