import "./fields.css";
import InputMask from "react-input-mask";

function Fields({inputValue, inputTitle, mask, setter, isValid, errorMessage}) {
  const handleInputChange = (event) => {
    setter(event.target.value);
  };
  return (
    <div className="all-field-ws">
      <div className="field-ws-title">{inputTitle}:</div>
      <InputMask
        value={inputValue}
        mask={mask}
        onChange={handleInputChange}
        className={isValid ? "ws-field" : "ws-field-invalid"}
      />
      {!isValid && <div className="ws-error">{errorMessage}</div>}
    </div>
  );
}
export default Fields;
