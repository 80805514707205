import { useContext } from "react";
import { QuestionsContext } from "../../contexts/questionsContext";
import QuestionOption from "../questionOption/QuestionOption";
import "./questionComponent.css";
function QuestionComponent({ question, pillar, questionId, isValid }) {
  const { actualPillar, answers, updateAnswer, clickedToFinish } =
    useContext(QuestionsContext);

  return (
    <div className="question-component-container">
      <div
        className="value-title"
        style={{ background: getColorTheme(pillar, isValid, clickedToFinish) }}
      >
        {question.valueTitle}
      </div>
      <div
        className="description"
        style={{ color: getColorTheme(pillar, isValid, clickedToFinish) }}
      >
        {question.valueDescription}
      </div>
      <div
        className="question-card"
        style={{ borderColor: getColorTheme(pillar, isValid, clickedToFinish) }}
      >
        <div
          className="question-card-title"
          style={{
            background: getColorTheme(pillar, isValid, clickedToFinish),
          }}
        >
          {question.questionTitle}
        </div>
        <div className="question-card-list">
          {question.questionOptions.map((optionText, index) => {
            return (
              <QuestionOption
                key={index}
                optionText={optionText}
                checked={
                  answers[`${actualPillar}-${questionId}`] === optionText
                }
                onChange={() =>
                  updateAnswer(questionId, actualPillar, optionText)
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function getColorTheme(pillar, isValid, isClickedToFinish) {
  if (isValid || !isClickedToFinish) {
    switch (pillar) {
      case "Pioneirismo":
        return "#2F4B7B";
      case "Times inovadores":
        return "#6797B3";
      case "Mente aberta":
        return "#78797D";
      default:
        break;
    }
  } else {
    return "#BA2424";
  }
}

export default QuestionComponent;
