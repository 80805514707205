import './buttonDiagnosis.css';

function ButtonDiagnosis({buttonText}){
    return(
    <div>
        <button className='button-diagnosis' type='button'>
            {buttonText}
        </button>
    </div>
    )
}
export default ButtonDiagnosis;