import "./workshop.css";
import ImgValter from "./assets/valterImg.svg";
import ImgValterMobile from "./assets/valterImgMobile.svg";
function Workshop() {
  const devWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return (
    <>
      {devWidth > 991 && <img src={ImgValter} alt="" />}
      {devWidth < 991 && <img src={ImgValterMobile} alt="" />}
    </>
  );
}
export default Workshop;
