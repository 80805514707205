import { Route, Routes } from "react-router-dom";
import WorkshopPage from "../pages/WorkshopPage/WorkshopPage";

function DiagnosisRoutes() {

  return (
    <Routes>
      <Route path="" element={<WorkshopPage />} />
    </Routes>
  );
}

export default DiagnosisRoutes;
