import React, { useState, useLayoutEffect } from "react";
import "./navbar.css";
import NavBarLogo from "./assets/navBarLogo.svg";
import NavBarLogoMobile from "./assets/navBarLogoMobile.svg";
import MenuImg from "./assets/menuImg.svg";

function NavBar({ navbarText }) {
  const ebookPath = process.env.REACT_APP_PATH_EBOOK || "";
  const ebookStyle = {
    color: "#ffb800",
  };

  const [isClicked, setIsClicked] = useState(false);
  const [devWidth, setDevWidth] = useState(
    window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  );

  const handleSelectFocus = () => {
    setIsClicked(!isClicked);
  };

  useLayoutEffect(() => {
    setDevWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );
  }, []);

  return (
    <>
      {devWidth > 991 && (
        <div className="navbar">
          <div className="navbar-title">
            <img src={NavBarLogo} alt="Logo" />
            <div className="pipe">|</div>
            <div className="navbar-text">{navbarText}</div>
          </div>
          <nav className="navbar-options">
            <a href="./">Início</a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pieracciani.com.br/"
            >
              Site
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pieracciani.com.br/blog"
            >
              Blog
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pieracciani.com.br/consultoria#contact-form"
            >
              Contato
            </a>
          </nav>
        </div>
      )}
      {devWidth <= 991 && (
        <>
          <div className="navbar-mob">
            <div className="navbar-center">
              <div className="navbar-logo">
                <img src={NavBarLogoMobile} alt="" />
              </div>
              <div className="pipe-mob"></div>
              <div className="navbar-mob-title">{navbarText}</div>
            </div>
            <div className="navbar-menu" onClick={handleSelectFocus}>
              <img src={MenuImg} alt="" />
            </div>
          </div>
          <div
            className={`dropdown-menu ${isClicked ? "show-dropdown-menu" : ""}`}
          >
            <a href="./">Início</a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pieracciani.com.br/"
            >
              Site
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pieracciani.com.br/blog"
            >
              Blog
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pieracciani.com.br/consultoria#contact-form"
            >
              Contato
            </a>
            {ebookPath !== "" ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={ebookPath}
                style={ebookStyle}
              >
                E-Book
              </a>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default NavBar;
