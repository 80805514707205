import "./workshop.css";

function WorkshopText({color}){
    let styleColor={
        color: color
    }
    return(
     <div className="all-workshop-text">
        <div className="w-top-text">WORKSHOP</div>
        <div className="w-bottom-text" style={styleColor}>"Cultura para Inovação"</div>
     </div>
    )
}
export default WorkshopText;