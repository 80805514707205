export const howWorksPageTexts = {

    toptext:
        {
      title: "Antes de começar, entenda:",
      text1: "Nosso diagnóstico consiste em %10 perguntas% e cada uma é conectada a 1 valor.",
      text2: "E estes foram %divididos em 3 Pilares%, que tem o mesmo peso para o diagnóstico:",
    },
    pillars:{
       first:{
        title: "Pioneirismo",
        subTitle: "Olhar visionário e uma estratégia pioneira!",
        text: "É sobre experimentar, se abrir a novas possibilidades, fazer diferente e estimular o intraempreendedorismo!",
       },
       second:{
        title: "Times inovadores",
        subTitle: "Um time diverso e multidisciplinar faz a diferença!",
        text: "Pessoas reconhecidas, engajadas e estimuladas por estarem em um ambiente favorável à Inovação.",
       },
       third:{
        title: "Mente aberta",
        subTitle: "Sede por conhecimento, considerando o erro como fonte de aprendizado!",
        text: "É sobre ser resiliente e estimular o poder da troca, respeitando e acolhendo objeções.",
       },
    },
    bottomText:{
        text: "Agora que você já entendeu, podemos começar!",
    }
};
