import "./howWorksPillars.css";

function HowWorksPillars({ color, imgSvg, title, subtitle, text }) {
    let styleColor={
        color: color,
    }
  return (
    <div className="all-hwp-pillars" style={styleColor}>
      <div className="hwp-pillars-img">
        <img src={imgSvg} alt="" />
      </div>
      <div className="hwp-pillars-title">{title}</div>
      <div className="hwp-pillars-subtitle">{subtitle}</div>
      <div className="hwp-pillars-text">{text}</div>
    </div>
  );
}
export default HowWorksPillars;
