import "./sixthComponent.css";
import WorkshopText from "../../../shared/workshop/WorkshopText";
import KnowMoreButton from "../../../shared/workshop/KnowMoreButton";
import ValterImg from "../../../shared/workshop/ValterImg";
import { lpTexts } from "../../external/lpTexts";
function SixthComponent() {
  let texts = lpTexts.sixthComponent;
  const devWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return (
    <>
      {devWidth > 991 && (
        <div className="all-sixth-component">
          <div className="sixc-left">
            <div className="sixc-content">
              <div className="sixc-first-text">{texts.text1}</div>
              <div className="sixc-second-text">
                <WorkshopText color="#2f4b7b" />
              </div>
              <div className="sixc-third-text">{texts.text2}</div>
              <div className="sixc-button">
                <KnowMoreButton />
              </div>
            </div>
          </div>
          <div className="sixc-right">
            <div className="sixc-img">
              <ValterImg />
            </div>
          </div>
        </div>
      )}
      {devWidth < 991 && (
        <div className="all-six-mob">
          <div className="six-mob-left">
            <div className="six-mob-text1">{texts.text1}</div>
            <div className="six-mob-text2">
              <WorkshopText color="#2f4b7b" />
            </div>
            <div className="six-mob-text3">{texts.text2}</div>
            <div className="sixc-button">
              <KnowMoreButton />
            </div>
          </div>
          <div className="six-mob-img">
            <ValterImg />
          </div>
        </div>
      )}
    </>
  );
}
export default SixthComponent;
