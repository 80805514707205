import "./resultsDetailsBorder.css";
import Icon from "../resultLogo/Icon";
function ResultsDetailsBorder({ color, iconSvg, name, children }) {
  const styleBorder = {
    borderColor: color,
  };
  const styleTitleBorder = {
    color: color,
  };
  return (
    <div className="all-border">
      <div className="top-border">
        <div className="title-border" style={styleTitleBorder}>
          {name}
        </div>
        <div className="border-icon">
          <Icon iconSvg={iconSvg} />
        </div>
      </div>
      <div className="border-results-details" style={styleBorder}>
        <div className="border-children">{children}</div>
      </div>
    </div>
  );
}
export default ResultsDetailsBorder;
