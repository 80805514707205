import { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { questionsDb } from "../external/questionsDb";
import { resultsDb } from "../external/resultsDb";
import { companyInformationsContext } from "./companyInformationsContext";
import { LoadingContext } from "./loadingContext";

const QuestionsContext = createContext();

const pillars = ["Pioneirismo", "Times inovadores", "Mente aberta"];

function QuestionsProvider({ children }) {
  const { setIsLoading } = useContext(LoadingContext);
  const { email } = useContext(companyInformationsContext);

  const [questionsData, setQuestionsData] = useState(questionsDb[1]);
  const [actualPillar, setActualPillar] = useState(pillars[0]);
  const [answers, setAnswers] = useState({});
  const [detailsResults, setDetailsResults] = useState({});
  const [totalResults, setTotalResults] = useState({});
  const [questionsAnswered, setQuestionsAnswered] = useState([]);
  const [clickedToFinish, setClickedToFinish] = useState(false);
  const [resume, setResume] = useState({});

  useEffect(() => {
    let answersInLocalstorage = localStorage.getItem("answersDiagnosis");
    if (answersInLocalstorage) {
      let json = JSON.parse(answersInLocalstorage);
      setAnswers(json);
      let keys = Object.keys(json);
      setQuestionsAnswered(keys);
    }

    let finalTotalResultsInLocalstorage =
      localStorage.getItem("finalTotalResults");
    if (finalTotalResultsInLocalstorage) {
      let json = JSON.parse(finalTotalResultsInLocalstorage);
      setTotalResults(json);
    }

    let resumeInLocalstorage = localStorage.getItem("resume");
    if (resumeInLocalstorage) {
      let json = JSON.parse(resumeInLocalstorage);
      setResume(json);
    }

    let detailsResultsInLocalstorage = localStorage.getItem("detailsResults");
    if (detailsResultsInLocalstorage) {
      let json = JSON.parse(detailsResultsInLocalstorage);
      setDetailsResults(json);
    }
  }, []);

  useEffect(() => {
    let filtered = questionsDb.filter(
      (obj) => obj.pillarName === actualPillar
    )[0];
    console.log(filtered);
    setQuestionsData(filtered);
  }, [actualPillar]);

  const updateAnswer = (questionId, pillar, answer) => {
    let id = `${pillar}-${questionId}`;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: answer,
    }));
  };

  useEffect(() => {
    const saveAnswersToLocalstorage = () => {
      let keys = Object.keys(answers);
      if (keys.length !== 0) {
        localStorage.setItem("answersDiagnosis", JSON.stringify(answers));
      }
    };

    saveAnswersToLocalstorage();

    let keys = Object.keys(answers);
    setQuestionsAnswered(keys);
  }, [answers]);

  function nextPillar() {
    setActualPillar(pillars[pillars.indexOf(actualPillar) + 1]);
  }

  function popPillar() {
    setActualPillar(pillars[pillars.indexOf(actualPillar) - 1]);
  }

  function formatDetails(details) {
    var formattedString = "";
    Object.keys(details).forEach((key) => {
      formattedString += `${key}: ${details[key].value} \n`;
    });
    return formattedString;
  }

  async function generateResults() {
    let totalResults = {};
    let details = {};
    let resume = {};
    Object.keys(answers).forEach((answerKey) => {
      let value = answers[answerKey][0];
      let texts = resultsDb.resultsDetails[answerKey][value];

      let pillar = answerKey.split("-")[0];
      let oldTotalResults = totalResults[pillar] || [];
      totalResults[pillar] = [...oldTotalResults, value];

      details[answerKey] = {
        value: value,
        title: texts.title,
        text: texts.text,
      };
    });
    let finalTotalResults = {};
    Object.keys(totalResults).forEach((pillar) => {
      let len = totalResults[pillar].length;
      let list = totalResults[pillar];
      let sum = list.reduce((sum, x) => sum + Number(x), 0);
      let mean = sum / len;
      let texts = resultsDb.results[pillar];
      let getResume = resultsDb.Resumes;
      if (mean < 2.33) {
        finalTotalResults[pillar] = {
          value: mean,
          title: texts.bad.title,
          text: texts.bad.text,
        };
      } else if (mean <= 3.66) {
        finalTotalResults[pillar] = {
          value: mean,
          title: texts.medium.title,
          text: texts.medium.text,
        };
      } else {
        finalTotalResults[pillar] = {
          value: mean,
          title: texts.good.title,
          text: texts.good.text,
        };
      }

      totalResults[pillar] = mean;
      /*all 3 equal*/
      if (
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Pioneirismo"] >= 4
      ) {
        resume = {
          title: getResume.allFourPlus.title,
          text: getResume.allFourPlus.text,
        };
      } else if (
        4 > totalResults["Times inovadores"] &&
        2 < totalResults["Times inovadores"] &&
        4 > totalResults["Mente aberta"] &&
        2 < totalResults["Mente aberta"] &&
        4 > totalResults["Pioneirismo"] &&
        2 < totalResults["Pioneirismo"]
      ) {
        resume = {
          title: getResume.allAroundThree.title,
          text: getResume.allAroundThree.text,
        };
      } else if (
        totalResults["Times inovadores"] <= 2 &&
        totalResults["Mente aberta"] <= 2 &&
        totalResults["Pioneirismo"] <= 2
      ) {
        resume = {
          title: getResume.allTwoLess.title,
          text: getResume.allTwoLess.text,
        };
      } else if (
        /* two bigger and one mid*/
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Mente aberta"] >= 4 &&
        4 > totalResults["Pioneirismo"] &&
        totalResults["Pioneirismo"] > 2
      ) {
        resume = {
          title: getResume.duoBigger.title
            .replace("%X%", "Times inovadores")
            .replace("%Y%", "Mente aberta"),
          text: getResume.soloAroundThree.text.replace("%X%", "Pioneirismo"),
        };
      } else if (
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Pioneirismo"] >= 4 &&
        4 > totalResults["Mente aberta"] &&
        totalResults["Mente aberta"] > 2
      ) {
        resume = {
          title: getResume.duoBigger.title
            .replace("%X%", "Times inovadores")
            .replace("%Y%", "Pioneirismo"),
          text: getResume.soloAroundThree.text.replace("%X%", "Mente aberta"),
        };
      } else if (
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Pioneirismo"] >= 4 &&
        4 > totalResults["Times inovadores"] &&
        totalResults["Times inovadores"] > 2
      ) {
        resume = {
          title: getResume.duoBigger.title
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Pioneirismo"),
          text: getResume.soloAroundThree.text.replace(
            "%X%",
            "Times inovadores"
          ),
        };
      } else if (
        /*two bigger and one smaller*/
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Pioneirismo"] <= 2
      ) {
        resume = {
          title: getResume.duoBigger.title
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Times inovadores"),
          text: getResume.soloSmaller.text.replace("%X%", "Pioneirismo"),
        };
      } else if (
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Pioneirismo"] >= 4 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title: getResume.duoBigger.title
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Pioneirismo"),
          text: getResume.soloSmaller.text.replace("%X%", "Times inovadores"),
        };
      } else if (
        totalResults["Pioneirismo"] >= 4 &&
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Mente aberta"] <= 2
      ) {
        resume = {
          title: getResume.duoBigger.title
            .replace("%X%", "Pioneirismo")
            .replace("%Y%", "Times inovadores"),
          text: getResume.soloSmaller.text.replace("%X%", "Mente aberta"),
        };
      } else if (
        /*two mid and one smaller*/
        totalResults["Pioneirismo"] < 4 &&
        totalResults["Pioneirismo"] > 2 &&
        totalResults["Mente aberta"] < 4 &&
        totalResults["Mente aberta"] > 2 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title: getResume.duoAroundThree.title
            .replace("%X%", "Pioneirismo")
            .replace("%Y%", "Mente aberta"),
          text: getResume.soloSmaller.text.replace("%X%", "Times inovadores"),
        };
      } else if (
        totalResults["Pioneirismo"] < 4 &&
        totalResults["Pioneirismo"] > 2 &&
        totalResults["Times inovadores"] < 4 &&
        totalResults["Times inovadores"] > 2 &&
        totalResults["Mente aberta"] <= 2
      ) {
        resume = {
          title: getResume.duoAroundThree.title
            .replace("%X%", "Pioneirismo")
            .replace("%Y%", "Times inovadores"),
          text: getResume.soloSmaller.text.replace("%X%", "Mente aberta"),
        };
      } else if (
        totalResults["Times inovadores"] < 4 &&
        totalResults["Times inovadores"] > 2 &&
        totalResults["Mente aberta"] < 4 &&
        totalResults["Mente aberta"] > 2 &&
        totalResults["Pioneirismo"] <= 2
      ) {
        resume = {
          title: getResume.duoAroundThree.title
            .replace("%X%", "Times inovadores")
            .replace("%Y%", "Mente aberta"),
          text: getResume.soloSmaller.text.replace("%X%", "Pioneirismo"),
        };
      } else if (
        /*one bigger and two mid*/
        totalResults["Pioneirismo"] >= 4 &&
        totalResults["Mente aberta"] < 4 &&
        totalResults["Mente aberta"] > 2 &&
        totalResults["Times inovadores"] < 4 &&
        totalResults["Times inovadores"] > 2
      ) {
        resume = {
          title: getResume.soloBigger.title.replace("%X%", "Pioneirismo"),
          text: getResume.duoAroundThree.text
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Times inovadores"),
        };
      } else if (
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Pioneirismo"] < 4 &&
        totalResults["Pioneirismo"] > 2 &&
        totalResults["Times inovadores"] < 4 &&
        totalResults["Times inovadores"] > 2
      ) {
        resume = {
          title: getResume.soloBigger.title.replace("%X%", "Mente aberta"),
          text: getResume.duoAroundThree.text
            .replace("%X%", "Pioneirismo")
            .replace("%Y%", "Times inovadores"),
        };
      } else if (
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Mente aberta"] < 4 &&
        totalResults["Mente aberta"] > 2 &&
        totalResults["Pioneirismo"] < 4 &&
        totalResults["Pioneirismo"] > 2
      ) {
        resume = {
          title: getResume.soloBigger.title.replace("%X%", "Times inovadores"),
          text: getResume.duoAroundThree.text
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Pioneirismo"),
        };
      } else if (
        /*one bigger and two smaller*/
        totalResults["Pioneirismo"] >= 4 &&
        totalResults["Mente aberta"] <= 2 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title: getResume.soloBigger.title.replace("%X%", "Pioneirismo"),
          text: getResume.duoSmaller.text
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Times inovadores"),
        };
      } else if (
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Pioneirismo"] <= 2 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title: getResume.soloBigger.title.replace("%X%", "Mente aberta"),
          text: getResume.duoSmaller.text
            .replace("%X%", "Pioneirismo")
            .replace("%Y%", "Times inovadores"),
        };
      } else if (
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Mente aberta"] <= 2 &&
        totalResults["Pioneirismo"] <= 2
      ) {
        resume = {
          title: getResume.soloBigger.title.replace("%X%", "Times inovadores"),
          text: getResume.duoSmaller.text
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Pioneirismo"),
        };
      } else if (
        /*one mid and two smaller*/
        totalResults["Pioneirismo"] < 4 &&
        totalResults["Pioneirismo"] > 2 &&
        totalResults["Mente aberta"] <= 2 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title: getResume.soloAroundThree.title.replace("%X%", "Pioneirismo"),
          text: getResume.duoSmaller.text
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Times inovadores"),
        };
      } else if (
        totalResults["Mente aberta"] < 4 &&
        totalResults["Mente aberta"] > 2 &&
        totalResults["Pioneirismo"] <= 2 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title: getResume.soloAroundThree.title.replace("%X%", "Mente aberta"),
          text: getResume.duoSmaller.text
            .replace("%X%", "Pioneirismo")
            .replace("%Y%", "Times inovadores"),
        };
      } else if (
        totalResults["Times inovadores"] < 4 &&
        totalResults["Times inovadores"] > 2 &&
        totalResults["Mente aberta"] <= 2 &&
        totalResults["Pioneirismo"] <= 2
      ) {
        resume = {
          title: getResume.soloAroundThree.title.replace(
            "%X%",
            "Times inovadores"
          ),
          text: getResume.duoSmaller.text
            .replace("%X%", "Mente aberta")
            .replace("%Y%", "Pioneirismo"),
        };
      } else if (
        /*sequence (one bigger, one mid and one smaller)*/
        totalResults["Pioneirismo"] >= 4 &&
        totalResults["Mente aberta"] < 4 &&
        totalResults["Mente aberta"] > 2 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title:
            getResume.soloBigger.title.replace("%X%", "Pioneirismo") +
            " " +
            getResume.soloAroundThree.title.replace("%X%", "Mente aberta"),
          text: getResume.soloSmaller.text.replace("%X%", "Times inovadores"),
        };
      } else if (
        totalResults["Pioneirismo"] >= 4 &&
        totalResults["Times inovadores"] < 4 &&
        totalResults["Times inovadores"] > 2 &&
        totalResults["Mente aberta"] <= 2
      ) {
        resume = {
          title:
            getResume.soloBigger.title.replace("%X%", "Pioneirismo") +
            " " +
            getResume.soloAroundThree.title.replace("%X%", "Times inovadores"),
          text: getResume.soloSmaller.text.replace("%X%", "Mente aberta"),
        };
      } else if (
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Pioneirismo"] < 4 &&
        totalResults["Pioneirismo"] > 2 &&
        totalResults["Times inovadores"] <= 2
      ) {
        resume = {
          title:
            getResume.soloBigger.title.replace("%X%", "Mente aberta") +
            " " +
            getResume.soloAroundThree.title.replace("%X%", "Pioneirismo"),
          text: getResume.soloSmaller.text.replace("%X%", "Times inovadores"),
        };
      } else if (
        totalResults["Mente aberta"] >= 4 &&
        totalResults["Times inovadores"] < 4 &&
        totalResults["Times inovadores"] > 2 &&
        totalResults["Pioneirismo"] <= 2
      ) {
        resume = {
          title:
            getResume.soloBigger.title.replace("%X%", "Mente aberta") +
            " " +
            getResume.soloAroundThree.title.replace("%X%", "Times inovadores"),
          text: getResume.soloSmaller.text.replace("%X%", "Pioneirismo"),
        };
      } else if (
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Mente aberta"] < 4 &&
        totalResults["Mente aberta"] > 2 &&
        totalResults["Pioneirismo"] <= 2
      ) {
        resume = {
          title:
            getResume.soloBigger.title.replace("%X%", "Times inovadores") +
            " " +
            getResume.soloAroundThree.title.replace("%X%", "Mente aberta"),
          text: getResume.soloSmaller.text.replace("%X%", "Pioneirismo"),
        };
      } else if (
        totalResults["Times inovadores"] >= 4 &&
        totalResults["Pioneirismo"] < 4 &&
        totalResults["Pioneirismo"] > 2 &&
        totalResults["Mente aberta"] <= 2
      ) {
        resume = {
          title:
            getResume.soloBigger.title.replace("%X%", "Times inovadores") +
            " " +
            getResume.soloAroundThree.title.replace("%X%", "Pioneirismo"),
          text: getResume.soloSmaller.text.replace("%X%", "Mente aberta"),
        };
      }
    });
    setTotalResults(finalTotalResults);
    setDetailsResults(details);
    setResume(resume);
    saveResultsToLocalstorage(finalTotalResults, details, resume);
    sendResultsToRd(
      totalResults["Mente aberta"],
      totalResults["Times inovadores"],
      totalResults["Pioneirismo"],
      details
    );
  }

  function validateAnswers() {
    let numberOfQuestions = 0;
    questionsDb.forEach((pillar) => {
      numberOfQuestions += pillar.questions.length;
    });
    return questionsAnswered.length === numberOfQuestions;
  }

  function validatePillarAnswers() {
    let numberOfQuestions = 0;
    questionsDb.forEach((pillar) => {
      if (pillar.pillarName === actualPillar) {
        numberOfQuestions += pillar.questions.length;
      }
    });

    let numberOfAnswers = questionsAnswered.filter((question) => {
      return question.split("-")[0] === actualPillar;
    }).length;

    return numberOfAnswers === numberOfQuestions;
  }

  function getProgress() {
    let numberOfQuestions = 0;
    questionsDb.forEach((pillar) => {
      numberOfQuestions += pillar.questions.length;
    });
    return (questionsAnswered.length / numberOfQuestions) * 100;
  }

  function saveResultsToLocalstorage(finalTotalResults, details, resume) {
    localStorage.setItem(
      "finalTotalResults",
      JSON.stringify(finalTotalResults)
    );
    localStorage.setItem("detailsResults", JSON.stringify(details));
    localStorage.setItem("resume", JSON.stringify(resume));
  }

  async function sendResultsToRd(
    resOpenMinded,
    resInnovationTeams,
    resPioneering,
    details
  ) {
    setIsLoading(true);

    const identifier = process.env.REACT_APP_RD_IDENTIFIER_RESULTS || "";
    const path = process.env.REACT_APP_PATH_RD_STATION || "";
    const apiKey = process.env.REACT_APP_KEY_RD_STATION || "";

    let payload = {
      event_type: "CONVERSION",
      event_family: "CDP",
      payload: {
        conversion_identifier: identifier,
        email: email,
        cf_resultado_diagnostico_mente_aberta: resOpenMinded.toString(),
        cf_resultado_diagnostico_pessoas: resInnovationTeams.toString(),
        cf_resultado_diagnostico_pioneirismo: resPioneering.toString(),
        cf_cf_resultado_diagnostico_completo: formatDetails(details),
      },
    };

    if (identifier !== "" && path !== "" && apiKey !== "") {
      try {
        await axios.post(`${path}?api_key=${apiKey}`, payload);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        alert("Ocorreu algum erro, tente novamente.");
      }
    } else {
      console.log("Faltando envs");
      console.log("payload:", payload);
      setIsLoading(false);
    }
  }

  return (
    <QuestionsContext.Provider
      value={{
        actualPillar,
        setActualPillar,
        questionsData,
        answers,
        updateAnswer,
        nextPillar,
        popPillar,
        pillars,
        totalResults,
        detailsResults,
        generateResults,
        validateAnswers,
        questionsAnswered,
        clickedToFinish,
        setClickedToFinish,
        validatePillarAnswers,
        getProgress,
        resume,
        setResume,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
}

export { QuestionsContext, QuestionsProvider };
