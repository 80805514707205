import { createContext, useState, useEffect } from "react";

const ScrollContext = createContext();

function ScrollProvider({ children }) {
  const [scrollPos, setScrollPos] = useState(0);
  const [progressBarBottom, setProgressBarBottom] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setScrollPos(window.scrollY);
    };
    window.addEventListener("scroll", onScroll);
  }, [scrollPos]);

  useEffect(() => {
    function visibleFooterSize() {
      const footer = document.querySelector(".footer"); // Substitua 'footer' pelo seletor apropriado, se necessário

      if (footer) {
        const rect = footer.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          if (rect.bottom > window.innerHeight) {
            return window.innerHeight - rect.top;
          } else {
            return rect.height;
          }
        }
      }

      return 0;
    }

    setProgressBarBottom(visibleFooterSize());
  }, [scrollPos]);

  return (
    <ScrollContext.Provider
      value={{
        scrollPos,
        progressBarBottom,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
}

export { ScrollContext, ScrollProvider };
