export const resultsDb = {
  results: {
    Pioneirismo: {
      bad: {
        title: "Promova uma Mentalidade Inovadora",
        text: "Inicie uma campanha de conscientização sobre a importância do pioneirismo e da inovação na empresa. Organize workshops e palestras que demonstrem como o intraempreendedorismo e o pensamento fora da caixa podem gerar valor para a organização. Crie um ambiente em que os funcionários sintam que suas ideias são valorizadas e incentivadas.",
      },
      medium: {
        title: "Fomente a Experimentação Estruturada",
        text: "Estabeleça um programa de incentivo à experimentação com processos bem definidos para testar ideias inovadoras. Forneça recursos e suporte para que os funcionários possam implementar projetos-piloto. Reconheça e recompense os funcionários que propõem e implementam com sucesso projetos inovadores, criando uma cultura de recompensa à inovação.",
      },
      good: {
        title: "Promova Parcerias e Colaborações Estratégicas",
        text: "Busque ativamente parcerias estratégicas com universidades, startups e outros players externos para promover a inovação. Mantenha uma rede ativa de inovação aberta para compartilhar conhecimento e ideias com parceiros externos. Crie um sistema de recompensas que incentive os funcionários a buscar e estabelecer conexões externas que contribuam para a inovação da empresa.",
      },
    },
    "Times inovadores": {
      bad: {
        title: "Promova o Reconhecimento e Bem-Estar",
        text: "Implemente programas de reconhecimento e recompensa que valorizem os funcionários. Ofereça benefícios que promovam o bem-estar, como horários flexíveis, assistência à saúde mental e programas de qualidade de vida. Realize pesquisas de clima organizacional para identificar áreas que precisam de melhorias e tome medidas para abordar as preocupações dos funcionários.",
      },
      medium: {
        title: "Crie um Ambiente Inclusivo",
        text: "Estabeleça políticas de diversidade e inclusão que garantam que todas as vozes sejam ouvidas e respeitadas. Realize treinamentos de sensibilização e conscientização sobre diversidade para promover um ambiente de trabalho mais inclusivo. Implemente práticas de recrutamento e seleção que promovam a diversidade cultural, de gênero, raça, orientação sexual.",
      },
      good: {
        title: "Desenvolva Líderes que Valorizam as Pessoas",
        text: "Invista no desenvolvimento de lideranças que coloquem as pessoas em primeiro lugar. Ofereça programas de treinamento de liderança que enfatizem a importância da empatia, do respeito e do apoio às necessidades dos funcionários. Reconheça e promova líderes que demonstrem consistentemente essas qualidades. Implemente programas de mentoria e coaching para desenvolver líderes inclusivos e voltados para o bem-estar da equipe.",
      },
    },
    "Mente aberta": {
      bad: {
        title: "Desenvolva uma Cultura de Aprendizado Contínuo",
        text: "Implemente programas de treinamento e capacitação que promovam o desenvolvimento profissional dos funcionários. Ofereça cursos, workshops e recursos online para que os colaboradores possam adquirir novos conhecimentos e habilidades. Estimule a autodidaxia e a busca por aprendizado contínuo.",
      },
      medium: {
        title: "Crie Espaços de Diálogo Construtivo",
        text: "Estabeleça fóruns de discussão e grupos de trabalho para debater ideias e objeções de forma construtiva. Promova uma cultura em que a divergência de opiniões seja respeitada e valorizada. Implemente um sistema de gestão do conhecimento para capturar e compartilhar lições aprendidas com erros e sucessos passados. Realize sessões de feedback regularmente para identificar oportunidades de melhoria",
      },
      good: {
        title: "Promova a Liderança pelo Exemplo",
        text: "Incentive a liderança a demonstrar resiliência e abertura para aprender com os erros. Estabeleça programas de mentoria e coaching para líderes e funcionários, ajudando-os a lidar com desafios de maneira construtiva. Reconheça e celebre as histórias de sucesso que surgiram após desafios e adversidades, destacando a importância da perseverança e do aprendizado contínuo.",
      },
    },
  },

  resultsDetails: {
    "Pioneirismo-0": {
      1: {
        title: "Encoraje a Abertura à Inovação",
        text: "Incentive uma mentalidade mais aberta à inovação em todos os níveis da empresa. Promova a ideia de que as melhores ideias podem vir de qualquer lugar, não apenas da alta liderança. Realize sessões de brainstorming e crie um ambiente onde os funcionários se sintam à vontade para compartilhar suas ideias sem medo de retaliação",
      },
      2: {
        title: "Encoraje a Abertura à Inovação",
        text: "Incentive uma mentalidade mais aberta à inovação em todos os níveis da empresa. Promova a ideia de que as melhores ideias podem vir de qualquer lugar, não apenas da alta liderança. Realize sessões de brainstorming e crie um ambiente onde os funcionários se sintam à vontade para compartilhar suas ideias sem medo de retaliação",
      },
      3: {
        title: "Crie um Sistema de Reconhecimento e Recompensas",
        text: "Estabeleça um sistema de reconhecimento que valorize e recompense os profissionais que contribuem com ideias inovadoras, mesmo que não façam parte da alta liderança. Reconheça publicamente seus esforços e impacto positivo. Isso pode incluir prêmios, bônus ou oportunidades de desenvolvimento.",
      },
      4: {
        title: "Amplie o Suporte à Inovação",
        text: 'Continuem a fortalecer o ambiente de inovação, mas não se acomodem. Mantenham o foco em apoiar os profissionais que agem como "donos do negócio". Forneçam recursos adicionais, como financiamento, mentoria e tempo dedicado a projetos inovadores. Promovam colaborações interdepartamentais para maximizar o impacto das ideias inovadoras.',
      },
      5: {
        title: "Amplie o Suporte à Inovação",
        text: 'Continuem a fortalecer o ambiente de inovação, mas não se acomodem. Mantenham o foco em apoiar os profissionais que agem como "donos do negócio". Forneçam recursos adicionais, como financiamento, mentoria e tempo dedicado a projetos inovadores. Promovam colaborações interdepartamentais para maximizar o impacto das ideias inovadoras.',
      },
    },
    "Pioneirismo-1": {
      1: {
        title: "Explore Parcerias Estratégicas",
        text: "É importante começar a explorar parcerias estratégicas com atores externos à organização, como clientes, universidades, centros de pesquisa e outras empresas. Identifique áreas onde a colaboração externa possa trazer valor, como pesquisa conjunta, desenvolvimento de produtos, ou compartilhamento de conhecimento. Considere a criação de um programa de inovação aberta para formalizar essas parcerias.",
      },
      2: {
        title: "Explore Parcerias Estratégicas",
        text: "É importante começar a explorar parcerias estratégicas com atores externos à organização, como clientes, universidades, centros de pesquisa e outras empresas. Identifique áreas onde a colaboração externa possa trazer valor, como pesquisa conjunta, desenvolvimento de produtos, ou compartilhamento de conhecimento. Considere a criação de um programa de inovação aberta para formalizar essas parcerias.",
      },
      3: {
        title: "Expandir e Aprofundar as Parcerias",
        text: "Continue a desenvolver parcerias colaborativas, mas busque expandir e aprofundar essas conexões. Avalie os resultados das iniciativas existentes e identifique áreas onde as parcerias podem ser ampliadas ou replicadas em outros setores da organização. Promova uma cultura de colaboração e compartilhamento de conhecimento interno para incentivar a participação de mais equipes e setores em projetos colaborativos com o ecossistema. Explore a criação de comunidades de prática externas e internas para facilitar a troca de ideias e experiências.",
      },
      4: {
        title: "Aprofunde a Integração com o Ecossistema de Inovação",
        text: "Continue a fortalecer e expandir as parcerias colaborativas com atores externos. Busque oportunidades para uma integração mais profunda com o ecossistema de inovação, participando ativamente de projetos, redes e consórcios. Considere a criação de programas de aceleração de startups, a participação em eventos de inovação e a busca contínua por soluções inovadoras fora da organização. Promova a disseminação das melhores práticas de colaboração internamente para garantir que a cultura de inovação aberta seja mantida e fortalecida.",
      },
      5: {
        title: "Aprofunde a Integração com o Ecossistema de Inovação",
        text: "Continue a fortalecer e expandir as parcerias colaborativas com atores externos. Busque oportunidades para uma integração mais profunda com o ecossistema de inovação, participando ativamente de projetos, redes e consórcios. Considere a criação de programas de aceleração de startups, a participação em eventos de inovação e a busca contínua por soluções inovadoras fora da organização. Promova a disseminação das melhores práticas de colaboração internamente para garantir que a cultura de inovação aberta seja mantida e fortalecida.",
      },
    },
    "Pioneirismo-2": {
      1: {
        title: "Promova uma Cultura de Experimentação",
        text: "É crucial começar a promover uma cultura de experimentação na empresa. Isso inclui educar a liderança sobre os benefícios de experimentar novas soluções e reduzir a burocracia em torno de testes e ajustes. Crie um ambiente onde os funcionários se sintam incentivados a propor e testar ideias inovadoras. Incentive a criação de um comitê de inovação que avalie e apoie projetos experimentais.",
      },
      2: {
        title: "Promova uma Cultura de Experimentação",
        text: "É crucial começar a promover uma cultura de experimentação na empresa. Isso inclui educar a liderança sobre os benefícios de experimentar novas soluções e reduzir a burocracia em torno de testes e ajustes. Crie um ambiente onde os funcionários se sintam incentivados a propor e testar ideias inovadoras. Incentive a criação de um comitê de inovação que avalie e apoie projetos experimentais.",
      },
      3: {
        title: "Reforce a Estrutura e o Aprendizado",
        text: "Continue a realizar experimentos, mas busque reforçar a estrutura e o aprendizado em torno dessas atividades. Isso pode envolver a alocação de recursos adicionais para apoiar experimentos e o desenvolvimento de programas de treinamento para capacitar os funcionários em métodos de experimentação. Crie um sistema de acompanhamento e avaliação mais robusto para medir os resultados e o aprendizado gerados pelos experimentos. Compartilhe as lições aprendidas internamente para incentivar uma cultura de aprendizado contínuo.",
      },
      4: {
        title: "Mantenha a Inovação Constante",
        text: 'Continue a liderar o caminho em termos de experimentação e inovação. Encoraje os funcionários a manter o ritmo de experimentação, não apenas com inovações incrementais, mas também com soluções "fora da caixa". Explore a criação de laboratórios de inovação ou espaços de experimentação onde as equipes possam colaborar e testar ideias de forma eficiente. Promova a cultura de compartilhamento de conhecimento e melhores práticas para garantir que o experimentalismo seja mantido como um valor central da empresa.',
      },
      5: {
        title: "Mantenha a Inovação Constante",
        text: 'Continue a liderar o caminho em termos de experimentação e inovação. Encoraje os funcionários a manter o ritmo de experimentação, não apenas com inovações incrementais, mas também com soluções "fora da caixa". Explore a criação de laboratórios de inovação ou espaços de experimentação onde as equipes possam colaborar e testar ideias de forma eficiente. Promova a cultura de compartilhamento de conhecimento e melhores práticas para garantir que o experimentalismo seja mantido como um valor central da empresa.',
      },
    },
    "Pioneirismo-3": {
      1: {
        title: "Promova uma Cultura de Inovação",
        text: "É crucial começar a promover uma cultura de inovação na empresa. Isso envolve a criação de um ambiente onde os funcionários se sintam incentivados a sugerir ideias inovadoras e desafiar os procedimentos existentes. Crie um canal de comunicação dedicado para coletar e avaliar as sugestões dos funcionários. Estabeleça recompensas e reconhecimento para incentivar a geração de ideias.",
      },
      2: {
        title: "Promova uma Cultura de Inovação",
        text: "É crucial começar a promover uma cultura de inovação na empresa. Isso envolve a criação de um ambiente onde os funcionários se sintam incentivados a sugerir ideias inovadoras e desafiar os procedimentos existentes. Crie um canal de comunicação dedicado para coletar e avaliar as sugestões dos funcionários. Estabeleça recompensas e reconhecimento para incentivar a geração de ideias.",
      },
      3: {
        title: "Estabeleça Práticas de Inovação",
        text: "Continue a buscar a reinvenção ocasional na empresa, mas busque estabelecer práticas mais estruturadas para promover ideias de melhorias e ações diferentes. Isso pode envolver a criação de um programa de inovação que forneça recursos e suporte para transformar ideias em ações. Estabeleça metas e métricas para avaliar o impacto das inovações.",
      },
      4: {
        title: "Mantenha e Amplie a Cultura de Inovação",
        text: "Continue a liderar em termos de inovação e pensamento diferente. Mantenha os processos e canais dedicados para os funcionários sugerirem mudanças e melhorias. Promova uma cultura de aprendizado contínuo, onde o inconformismo seja valorizado e incentivado. Explore programas de capacitação em criatividade e inovação para desenvolver ainda mais a mentalidade inovadora da equipe.",
      },
      5: {
        title: "Mantenha e Amplie a Cultura de Inovação",
        text: "Continue a liderar em termos de inovação e pensamento diferente. Mantenha os processos e canais dedicados para os funcionários sugerirem mudanças e melhorias. Promova uma cultura de aprendizado contínuo, onde o inconformismo seja valorizado e incentivado. Explore programas de capacitação em criatividade e inovação para desenvolver ainda mais a mentalidade inovadora da equipe.",
      },
    },
    "Times inovadores-0": {
      1: {
        title: "Inicie Diálogos sobre Diversidade",
        text: "É fundamental começar a promover diálogos abertos sobre a importância da diversidade na empresa. Isso envolve criar espaços onde os funcionários possam compartilhar suas perspectivas e preocupações sobre diversidade. Realize workshops de conscientização sobre diversidade para aumentar a compreensão e o apoio. Incentive a liderança a liderar com o exemplo ao promover a diversidade em todos os níveis da organização.",
      },
      2: {
        title: "Inicie Diálogos sobre Diversidade",
        text: "É fundamental começar a promover diálogos abertos sobre a importância da diversidade na empresa. Isso envolve criar espaços onde os funcionários possam compartilhar suas perspectivas e preocupações sobre diversidade. Realize workshops de conscientização sobre diversidade para aumentar a compreensão e o apoio. Incentive a liderança a liderar com o exemplo ao promover a diversidade em todos os níveis da organização.",
      },
      3: {
        title: "Implemente Práticas de Diversidade",
        text: "Continue a valorizar a diversidade e leve isso adiante implementando práticas efetivas para garantir uma equipe diversa. Isso pode envolver a criação de políticas de recrutamento que buscam ativamente candidatos diversos, a promoção de programas de desenvolvimento de liderança para grupos sub-representados e a criação de um ambiente inclusivo onde todas as vozes são ouvidas e respeitadas.",
      },
      4: {
        title: "Mantenha e Aprofunde a Cultura de Diversidade",
        text: "Continue a liderar em termos de diversidade e inclusão. Mantenha e amplie as práticas e políticas que promovem a diversidade em todos os aspectos da empresa. Promova a diversidade em todos os níveis hierárquicos e em todas as áreas da organização. Continue a educar e sensibilizar os funcionários sobre a importância da diversidade e da inclusão, e compartilhe histórias de sucesso que demonstram os benefícios da diversidade. Esteja aberto ao feedback dos funcionários e faça ajustes contínuos em suas políticas e práticas de diversidade para garantir que elas permaneçam relevantes e eficazes.",
      },
      5: {
        title: "Mantenha e Aprofunde a Cultura de Diversidade",
        text: "Continue a liderar em termos de diversidade e inclusão. Mantenha e amplie as práticas e políticas que promovem a diversidade em todos os aspectos da empresa. Promova a diversidade em todos os níveis hierárquicos e em todas as áreas da organização. Continue a educar e sensibilizar os funcionários sobre a importância da diversidade e da inclusão, e compartilhe histórias de sucesso que demonstram os benefícios da diversidade. Esteja aberto ao feedback dos funcionários e faça ajustes contínuos em suas políticas e práticas de diversidade para garantir que elas permaneçam relevantes e eficazes.",
      },
    },
    "Times inovadores-1": {
      1: {
        title: "Incentive a Valorização dos Colaboradores",
        text: "É fundamental que a empresa comece a reconhecer a importância das pessoas. Comece promovendo treinamentos de conscientização sobre a valorização dos colaboradores, mostrando como isso pode impactar positivamente a cultura organizacional e os resultados. Estabeleça indicadores para medir o engajamento dos funcionários e monitore regularmente.",
      },
      2: {
        title: "Incentive a Valorização dos Colaboradores",
        text: "É fundamental que a empresa comece a reconhecer a importância das pessoas. Comece promovendo treinamentos de conscientização sobre a valorização dos colaboradores, mostrando como isso pode impactar positivamente a cultura organizacional e os resultados. Estabeleça indicadores para medir o engajamento dos funcionários e monitore regularmente.",
      },
      3: {
        title: "Expanda as Iniciativas de Valorização",
        text: "Continuem a ouvir as demandas dos funcionários, mas busquem ampliar as ações de valorização. Isso pode incluir programas de reconhecimento mais abrangentes, treinamentos para líderes sobre liderança centrada nas pessoas e a criação de um ambiente onde as vozes dos colaboradores sejam ouvidas de maneira mais eficaz.",
      },
      4: {
        title: "Mantenha e Aprofunde o Compromisso com as Pessoas",
        text: "Continuem a investir em programas e ações que promovam a valorização dos colaboradores. Isso pode incluir a expansão de programas de desenvolvimento pessoal e profissional, a criação de canais de comunicação abertos para colaboradores compartilharem ideias e preocupações, e a implementação de políticas de equilíbrio entre trabalho e vida pessoal. Lembre-se de manter a transparência e o foco nas necessidades e no bem-estar das pessoas como um diferencial da empresa.",
      },
      5: {
        title: "Mantenha e Aprofunde o Compromisso com as Pessoas",
        text: "Continuem a investir em programas e ações que promovam a valorização dos colaboradores. Isso pode incluir a expansão de programas de desenvolvimento pessoal e profissional, a criação de canais de comunicação abertos para colaboradores compartilharem ideias e preocupações, e a implementação de políticas de equilíbrio entre trabalho e vida pessoal. Lembre-se de manter a transparência e o foco nas necessidades e no bem-estar das pessoas como um diferencial da empresa.",
      },
    },
    "Mente aberta-0": {
      1: {
        title: "Promova uma Cultura de Aprendizado",
        text: "É fundamental começar a promover uma cultura que valorize o aprendizado com base nos erros. Isso envolve mudar a mentalidade em relação ao fracasso, destacando que os erros são oportunidades de aprendizado. Incentive a comunicação aberta sobre falhas e crie um ambiente onde os funcionários se sintam seguros para compartilhar e discutir erros, sem medo de críticas construtivas.",
      },
      2: {
        title: "Promova uma Cultura de Aprendizado",
        text: "É fundamental começar a promover uma cultura que valorize o aprendizado com base nos erros. Isso envolve mudar a mentalidade em relação ao fracasso, destacando que os erros são oportunidades de aprendizado. Incentive a comunicação aberta sobre falhas e crie um ambiente onde os funcionários se sintam seguros para compartilhar e discutir erros, sem medo de críticas construtivas.",
      },
      3: {
        title: "Reforce o Aprendizado com Erros",
        text: "Continue a reconhecer a importância de aprender com os erros, mas busque ampliar os esforços para extrair lições valiosas de cada tentativa mal-sucedida. Promova sessões de revisão pós-projeto ou pós-iniciativa para analisar os erros e identificar maneiras de evitar repetições. Encoraje a documentação e o compartilhamento das lições aprendidas.",
      },
      4: {
        title: "Fortaleça a Abordagem de Aprendizado",
        text: "Continue a fortalecer a mentalidade de aprendizado com base nos erros, mas não se acomode. Mantenha a liderança e os colaboradores engajados na análise de falhas e na busca contínua por maneiras de melhorar. Estabeleça processos formais para documentar e compartilhar as lições aprendidas com toda a organização, garantindo que esses aprendizados sejam aplicados em futuras iniciativas.",
      },
      5: {
        title: "Fortaleça a Abordagem de Aprendizado",
        text: "Continue a fortalecer a mentalidade de aprendizado com base nos erros, mas não se acomode. Mantenha a liderança e os colaboradores engajados na análise de falhas e na busca contínua por maneiras de melhorar. Estabeleça processos formais para documentar e compartilhar as lições aprendidas com toda a organização, garantindo que esses aprendizados sejam aplicados em futuras iniciativas.",
      },
    },
    "Mente aberta-1": {
      1: {
        title: "Introduza a Gestão do Conhecimento",
        text: "É essencial começar a introduzir práticas de gestão do conhecimento na empresa. Isso pode envolver a criação de um sistema para capturar, organizar e compartilhar o conhecimento gerado internamente. Implemente programas de treinamento regulares para melhorar a capacitação técnica e comportamental dos funcionários. Incentive a criação de uma cultura de aprendizado contínuo, onde o conhecimento seja valorizado e incentivado.",
      },
      2: {
        title: "Introduza a Gestão do Conhecimento",
        text: "É essencial começar a introduzir práticas de gestão do conhecimento na empresa. Isso pode envolver a criação de um sistema para capturar, organizar e compartilhar o conhecimento gerado internamente. Implemente programas de treinamento regulares para melhorar a capacitação técnica e comportamental dos funcionários. Incentive a criação de uma cultura de aprendizado contínuo, onde o conhecimento seja valorizado e incentivado.",
      },
      3: {
        title: "Reforce a Captura e Disseminação do Conhecimento",
        text: "Continue a promover práticas de gestão do conhecimento, mas busque reforçar a captura e a disseminação do conhecimento na empresa. Isso pode incluir a implementação de ferramentas e sistemas mais eficazes para documentação e compartilhamento de informações. Estabeleça um programa de mentoria onde funcionários experientes possam transmitir seu conhecimento aos mais novos. Encoraje a liderança a priorizar a gestão do conhecimento como um elemento-chave para a inovação.",
      },
      4: {
        title: "Mantenha a Cultura de Aprendizado",
        text: "Continue a liderar em termos de gestão do conhecimento e aprendizado contínuo. Mantenha e aprofunde as práticas existentes para capturar, organizar e disseminar o conhecimento. Promova programas de treinamento regulares, fóruns, seminários e debates para manter os funcionários atualizados e engajados. Estabeleça um sistema de reconhecimento e recompensa para aqueles que contribuem significativamente para o compartilhamento do conhecimento.",
      },
      5: {
        title: "Mantenha a Cultura de Aprendizado",
        text: "Continue a liderar em termos de gestão do conhecimento e aprendizado contínuo. Mantenha e aprofunde as práticas existentes para capturar, organizar e disseminar o conhecimento. Promova programas de treinamento regulares, fóruns, seminários e debates para manter os funcionários atualizados e engajados. Estabeleça um sistema de reconhecimento e recompensa para aqueles que contribuem significativamente para o compartilhamento do conhecimento.",
      },
    },
    "Mente aberta-2": {
      1: {
        title: "Desenvolva Resiliência Organizacional",
        text: "É fundamental começar a desenvolver uma cultura de resiliência na empresa. Isso envolve treinamento para líderes e equipes sobre como lidar com adversidades e desafios de forma construtiva. Encoraje a criação de um ambiente onde as pessoas possam expressar suas preocupações e emoções, ao mesmo tempo em que mantêm o foco em soluções. Promova programas de desenvolvimento pessoal que fortaleçam a resiliência emocional dos funcionários.",
      },
      2: {
        title: "Desenvolva Resiliência Organizacional",
        text: "É fundamental começar a desenvolver uma cultura de resiliência na empresa. Isso envolve treinamento para líderes e equipes sobre como lidar com adversidades e desafios de forma construtiva. Encoraje a criação de um ambiente onde as pessoas possam expressar suas preocupações e emoções, ao mesmo tempo em que mantêm o foco em soluções. Promova programas de desenvolvimento pessoal que fortaleçam a resiliência emocional dos funcionários.",
      },
      3: {
        title: "Reforce a Capacidade de Adaptação",
        text: "Continue a fortalecer a capacidade da equipe de lidar com adversidades, mas busque também aprimorar a capacidade de se adaptar e aprender com os erros. Isso pode envolver a implementação de processos de avaliação pós-incidente para extrair lições de desafios passados. Promova a formação de equipes multifuncionais que possam abordar problemas de diferentes perspectivas e buscar soluções inovadoras.",
      },
      4: {
        title: "Mantenha a Cultura de Resiliência",
        text: "Continue a enfatizar e celebrar a perseverança e a resiliência como valores fundamentais da empresa. Lembre-se de que, mesmo quando as coisas estão indo bem, é importante manter uma mentalidade de aprendizado contínuo e prontidão para enfrentar desafios futuros. Promova a cultura de compartilhamento de histórias de sucesso em momentos de resiliência para inspirar e motivar outros membros da equipe.",
      },
      5: {
        title: "Mantenha a Cultura de Resiliência",
        text: "Continue a enfatizar e celebrar a perseverança e a resiliência como valores fundamentais da empresa. Lembre-se de que, mesmo quando as coisas estão indo bem, é importante manter uma mentalidade de aprendizado contínuo e prontidão para enfrentar desafios futuros. Promova a cultura de compartilhamento de histórias de sucesso em momentos de resiliência para inspirar e motivar outros membros da equipe.",
      },
    },
    "Mente aberta-3": {
      1: {
        title: "Promova uma Cultura de Diálogo Aberto",
        text: "É importante começar a promover uma cultura de diálogo aberto e respeitoso, onde as objeções são bem-vindas e valorizadas. Isso pode envolver a criação de um ambiente onde os funcionários se sintam à vontade para expressar suas opiniões, mesmo que sejam diferentes da maioria. Estabeleça normas de comunicação que incentivem o respeito mútuo e o debate construtivo.",
      },
      2: {
        title: "Promova uma Cultura de Diálogo Aberto",
        text: "É importante começar a promover uma cultura de diálogo aberto e respeitoso, onde as objeções são bem-vindas e valorizadas. Isso pode envolver a criação de um ambiente onde os funcionários se sintam à vontade para expressar suas opiniões, mesmo que sejam diferentes da maioria. Estabeleça normas de comunicação que incentivem o respeito mútuo e o debate construtivo.",
      },
      3: {
        title: "Fortaleça o Debate Construtivo",
        text: "Continue a valorizar a capacidade de dar pontos de vista divergentes, mas busque fortalecer o debate construtivo. Isso pode envolver a criação de fóruns ou grupos de discussão dedicados a tópicos específicos, nos quais as objeções e diferentes perspectivas sejam cuidadosamente debatidas. Incentive a liderança a demonstrar abertura para ouvir e considerar objeções.",
      },
      4: {
        title: "Mantenha e Aprofunde a Cultura de Respeito às Objeções",
        text: "Continue liderando em termos de respeito às objeções e debate construtivo. Mantenha e aprofunde as práticas que promovem o respeito às objeções em todos os níveis da organização. Incentive a formação de grupos de trabalho multidisciplinares que possam debater ideias e perspectivas diversas para chegar a soluções mais robustas. Compartilhe histórias de sucesso que demonstram como as objeções e o debate produtivo contribuíram para a melhoria das decisões e processos da empresa.",
      },
      5: {
        title: "Mantenha e Aprofunde a Cultura de Respeito às Objeções",
        text: "Continue liderando em termos de respeito às objeções e debate construtivo. Mantenha e aprofunde as práticas que promovem o respeito às objeções em todos os níveis da organização. Incentive a formação de grupos de trabalho multidisciplinares que possam debater ideias e perspectivas diversas para chegar a soluções mais robustas. Compartilhe histórias de sucesso que demonstram como as objeções e o debate produtivo contribuíram para a melhoria das decisões e processos da empresa.",
      },
    },
  },
  Resumes:{
     allFourPlus:{
      title: "Parabéns! A Cultura de Inovação em sua empresa é muito forte e disseminada!",
      text: "Esperamos que continue assim e cada vez mais iniciativas impactem pessoas e resultados!",
     },
     allAroundThree:{
      title: "A Cultura de Inovação em sua empresa reflete uma base equilibrada e consistente nos três pilares!",
      text: "No entanto, este Diagnóstico também revela um notável potencial para desenvolvimento. Recomendamos explorar oportunidades específicas em cada pilar para impulsionar ainda mais a inovação e fortalecer a cultura organizacional.",
     },
     allTwoLess:{
      title: "A Cultura de Inovação em sua empresa precisa se fortalecer e ser disseminada!",
      text: "Esperamos que com esse Diagnóstico sua empresa consiga refletir sobre e definir um plano de ação concreto e que impacte em um ambiente mais favorável à Inovação!",
     },
     soloBigger:{
      title: "O Pilar %X% é muito forte e presente em sua empresa!",
     },
     soloAroundThree:{
      title: "O Pilar %X% está presente em sua empresa, mas ainda há muito espaço para evolução!",
      text: "O Pilar %X% está presente em sua empresa, mas ainda há muito espaço para evolução!",
    },
    soloSmaller:{
      text: "Já o Pilar %X% precisa de atenção e ações concretas para que, junto dos demais, potencialize a Cultura de Inovação e os resultados obtidos por meio desta!",
    },
    duoBigger:{
      title: "Os Pilares %X% e %Y% são muito fortes e presentes em sua empresa!",
    },
    duoAroundThree:{
      title: "Os Pilares %X% e %Y% estão presentes em sua empresa, mas ainda há muito espaço para evolução!",
      text:"Os Pilares %X% e %Y% estão presentes em sua empresa, mas ainda há muito espaço para evolução!",
    },
    duoSmaller:{
      text:"Já os Pilares %X% e %Y% precisam de atenção e ações concretas para que, juntos dos demais, potencializem a Cultura de Inovação e os resultados obtidos por meio desta!",
    }
     },
};
