import FirstComponent from "../../components/firstComponent/FirstComponent";
import SecondComponent from "../../components/secondComponent/SecondComponent";
import ThirdComponent from "../../components/thirdComponent/ThirdComponent";
import FourthComponent from "../../components/fourthComponent/FourthComponent";
import FifithComponent from "../../components/fifthComponent/FifithComponent";
import SixthComponent from "../../components/sixthComponent/SixthComponent";
import SeventhComponent from "../../components/seventhComponent/SeventhComponent";
import Ebook from "../../components/ebook/Ebook";
import Scaffold from "../../../shared/scaffold/scaffold";
function InitialPage() {
  return (
    <>
      <Scaffold>
        <Ebook />
        <FirstComponent />
        <SecondComponent />
        <ThirdComponent />
        <FourthComponent />
        <FifithComponent />
        <SixthComponent />
        <SeventhComponent />
      </Scaffold>
    </>
  );
}
export default InitialPage;
