import "./header.css";
import HeaderFingerprint from "./assets/headerFingerprint.svg";
import HeaderImgMob from "./assets/headerImgMob.svg";
function Header() {
  const devWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  return (
    <header className="header">
      <img src={devWidth > 991 ? HeaderFingerprint : HeaderImgMob} alt="Logo" className="header-fingerprint" />
      <div className="header-text">
        Preencha seus dados para começar o diagnóstico
      </div>
    </header>
  );
}

export default Header;
