import "./pillarHeader.css";
import PioneeringSvg from "./assets/pioneering.svg";
import InnovationTeamsSvg from "./assets/innovationTeams.svg";
import OpenMindedSvg from "./assets/openMinded.svg";

function PillarHeader({ pillar }) {
  return (
    <>
      <div className="pillar-header">
        <img src={getLogo(pillar)} alt={pillar} className="pillar-img" />
      </div>
      <div
        className="pillar-header-text"
        style={{ color: getColorTheme(pillar) }}
      >
        {pillar}
      </div>
    </>
  );
}

function getLogo(pillar) {
  switch (pillar) {
    case "Pioneirismo":
      return PioneeringSvg;
    case "Times inovadores":
      return InnovationTeamsSvg;
    case "Mente aberta":
      return OpenMindedSvg;
    default:
      break;
  }
}

function getColorTheme(pillar) {
  switch (pillar) {
    case "Pioneirismo":
      return "#2F4B7B";
    case "Times inovadores":
      return "#6797B3";
    case "Mente aberta":
      return "#78797D";
    default:
      break;
  }
}

export default PillarHeader;
