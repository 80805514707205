import { useContext } from "react";
import "./resultsPage.css";
import Top from "../../components/resultsTop/ResultsTop";
import Graph from "../../components/resultsGraphic/Graphic";
import Results from "../../components/results/Results";
import ResultsBorder from "../../components/resultsDetailsBorder/ResultsDetailsBorder";
import DownloadButton from "../../components/buttonDownloadResult/ButtonDownloadResult";
import ResultsDetails from "../../components/resultsDetails/ResultsDetails";
import Workshop from "../../components/resultsWorkshop/ResultsWorkshop";
import PioneeringIcon from "../../components/resultLogo/assets/pioneeiringIcon.svg";
import OpenMindedIcon from "../../components/resultLogo/assets/openMindedIcon.svg";
import InnovationTeamsIcon from "../../components/resultLogo/assets/innovationTeamsIcon.svg";
import Scaffold from "../../../shared/scaffold/scaffold";
import { QuestionsContext } from "../../contexts/questionsContext";

function ResultsPage() {
  const { detailsResults, totalResults, resume } = useContext(QuestionsContext);
  if (Object.keys(totalResults).length !== 0) {
    return (
      <>
        <Scaffold>
          <Top />
          <div className="overview">Visão Geral:</div>
          <div id="pdf-page1">
            <div className="general-results">
              <div className="graph">
                <Graph
                  valueInovationTeams={`${totalResults["Times inovadores"].value}`}
                  valueOpenMinded={`${totalResults["Mente aberta"].value}`}
                  valuePioneering={`${totalResults["Pioneirismo"].value}`}
                  title={`${resume.title}`}
                  resume={`${resume.text}`}
                />
              </div>
              <div className="results">
                <Results
                  iconSvg={PioneeringIcon}
                  name="Pioneirismo"
                  value={`${totalResults["Pioneirismo"].value}`}
                  title={`${totalResults["Pioneirismo"].title}`}
                  text={`${totalResults["Pioneirismo"].text}`}
                  color="#2f4b7b"
                />
                <Results
                  iconSvg={InnovationTeamsIcon}
                  name="Times Inovadores"
                  value={`${totalResults["Times inovadores"].value}`}
                  title={`${totalResults["Times inovadores"].title}`}
                  text={`${totalResults["Times inovadores"].text}`}
                  color="#6797b3"
                />
                <Results
                  iconSvg={OpenMindedIcon}
                  name="Mente Aberta"
                  value={`${totalResults["Mente aberta"].value}`}
                  title={`${totalResults["Mente aberta"].title}`}
                  text={`${totalResults["Mente aberta"].text}`}
                  color="#78797d"
                />
              </div>
            </div>
          </div>

          <hr className="line" />
          <div id="pdf-page2">
            <div className="detailed-view">Visão detalhada:</div>
            <div className="details">
              <ResultsBorder
                color="#2f4b7b"
                iconSvg={PioneeringIcon}
                name="Pioneirismo"
              >
                <ResultsDetails
                  name="Intraempreendedorismo e senso de oportunidade"
                  value={`${detailsResults["Pioneirismo-0"].value}`}
                  title={`${detailsResults["Pioneirismo-0"].title}`}
                  text={`${detailsResults["Pioneirismo-0"].text}`}
                  color="#2f4b7b"
                />
                <ResultsDetails
                  name="Sem fronteiras - Sistema aberto"
                  value={`${detailsResults["Pioneirismo-1"].value}`}
                  title={`${detailsResults["Pioneirismo-1"].title}`}
                  text={`${detailsResults["Pioneirismo-1"].text}`}
                  color="#2f4b7b"
                />
                <ResultsDetails
                  name="Experimentalismo"
                  value={`${detailsResults["Pioneirismo-2"].value}`}
                  title={`${detailsResults["Pioneirismo-2"].title}`}
                  text={`${detailsResults["Pioneirismo-2"].text}`}
                  color="#2f4b7b"
                />
                <ResultsDetails
                  name="Pensar e fazer diferente"
                  value={`${detailsResults["Pioneirismo-3"].value}`}
                  title={`${detailsResults["Pioneirismo-3"].title}`}
                  text={`${detailsResults["Pioneirismo-3"].text}`}
                  color="#2f4b7b"
                />
              </ResultsBorder>
            </div>
          </div>

          <div id="pdf-page3">
            <div className="details">
              <ResultsBorder
                color="#6797b3"
                iconSvg={InnovationTeamsIcon}
                name="Times Inovadores"
              >
                <ResultsDetails
                  name="Diversidade"
                  value={`${detailsResults["Times inovadores-0"].value}`}
                  title={`${detailsResults["Times inovadores-0"].title}`}
                  text={`${detailsResults["Times inovadores-0"].text}`}
                  color="#6797b3"
                />
                <ResultsDetails
                  name="Pessoas em primeiro lugar"
                  value={`${detailsResults["Times inovadores-1"].value}`}
                  title={`${detailsResults["Times inovadores-1"].title}`}
                  text={`${detailsResults["Times inovadores-1"].text}`}
                  color="#6797b3"
                />
              </ResultsBorder>
            </div>
          </div>

          <div id="pdf-page4">
            <div className="details">
              <ResultsBorder
                color="#78797d"
                iconSvg={OpenMindedIcon}
                name="Mente Aberta"
              >
                <ResultsDetails
                  name="Erro como aprendizado"
                  value={`${detailsResults["Mente aberta-0"].value}`}
                  title={`${detailsResults["Mente aberta-0"].title}`}
                  text={`${detailsResults["Mente aberta-0"].text}`}
                  color="#78797d"
                />
                <ResultsDetails
                  name="Conhecimento como valor"
                  value={`${detailsResults["Mente aberta-1"].value}`}
                  title={`${detailsResults["Mente aberta-1"].title}`}
                  text={`${detailsResults["Mente aberta-1"].text}`}
                  color="#78797d"
                />
                <ResultsDetails
                  name="Perseverança/resiliência"
                  value={`${detailsResults["Mente aberta-2"].value}`}
                  title={`${detailsResults["Mente aberta-2"].title}`}
                  text={`${detailsResults["Mente aberta-2"].text}`}
                  color="#78797d"
                />
                <ResultsDetails
                  name="Respeitar e acolher objeções"
                  value={`${detailsResults["Mente aberta-3"].value}`}
                  title={`${detailsResults["Mente aberta-3"].title}`}
                  text={`${detailsResults["Mente aberta-3"].text}`}
                  color="#78797d"
                />
              </ResultsBorder>
            </div>
          </div>
          <DownloadButton textColor="#ffffff" backgroundColor="#6d7e91" />
          <div className="workshop-results">
            <Workshop />
          </div>
        </Scaffold>
      </>
    );
  }
  return <div>Erro ao gerar o resultado</div>;
}
export default ResultsPage;
