import "./personEach.css";
import PersonInformation from "../personInformations/PersonInformation";
import PersonPhoto from "../personPhoto/PersonPhoto";
import React, { useState, useRef, useEffect } from "react";
function PersonEach({ imgSvg, name, text, job, photoMargin }) {
  const dropdownRef = useRef(null);
  const [isClicked, setIsClicked] = useState(false);

  const handleSelectFocus = () => {
    setIsClicked(true);
  };
  const handleSelectBlur = () => {
    setIsClicked(false);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleSelectBlur();
      }
    }

    // Adiciona o evento de clique ao montar o componente
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove o evento de clique ao desmontar o componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div className="all-ep" ref={dropdownRef}>
      <div className="ep-photo"> 
        <PersonPhoto imgSvg={imgSvg} width="190px" height="190px" photoMargin={photoMargin} />
      </div>
      <div className="ep-name">{name}</div>
      <div className="ep-job-input" onClick={handleSelectFocus}>
        <div className="ep-job-name">{job}</div>
        <div className="ep-plus-ball">
          <div className="ep-plus">+</div>
        </div>
      </div>
      <div
        className={`ep-persons-information ${
          isClicked ? "show-persons-information" : ""
        }`}
      >
        <PersonInformation
          imgSvg={imgSvg}
          name={name}
          text={text}
          closeInformation={handleSelectBlur}
          photoMargin={photoMargin}
        />
      </div>
    </div>
  );
}
export default PersonEach;
