export const workshopTexts ={
    topics: {
        title: "O workshop abordará:",
        topics:{
            text1:"• Introdução aos 10 valores de uma cultura de inovação",
            text2:"• Dinâmica para elaboração do diagnóstico da cultura de inovação na visão dos líderes",
            text3:"• Análise do diagnóstico da cultura de inovação",
            text4:"• Discussão sobre os valores de inovação no contexto da empresa",
            text5:"• Desdobramento dos valores em atitudes e comportamentos",
            text6:"• Elaboração de planos de ação para fortalecimento da cultura de inovação",
            text7:"• Constituição do comitê de cultura de inovação",
        }
    },
    formTitle:{
        title: "Se interessou? Entre em contato!",
    }
}