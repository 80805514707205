import "./workshop.css";
import { useNavigate } from "react-router-dom";
function KnowMoreButton() {
  const navigate = useNavigate();
  return (
    <div
      className="all-know-more-button"
      onClick={() => {
        navigate("/workshop");
        window.scrollTo({ top: 0 });
      }}
    >
      <button className="know-more-button">Saiba mais</button>
    </div>
  );
}
export default KnowMoreButton;