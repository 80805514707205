import { useState, useEffect } from "react";
import "./firstComponent.css";
import { lpTexts } from "../../external/lpTexts";
import ButtonDiagnosis from "../buttonDiagnosis/ButtonDiagnosis";
import Pillars from "../pillars/Pillars";
import IconPioneering from "../pillars/assets/pioneering.svg";
import IconInnovationTeams from "../pillars/assets/innovationTeams.svg";
import IconOpenMind from "../pillars/assets/openMind.svg";
import IconPioneeringMob from "../pillars/assets/pioneeringMob.svg";
import IconInnovationTeamsMob from "../pillars/assets/innovationTeamsMob.svg";
import IconOpenMindMob from "../pillars/assets/openMindMob.svg";
import { useNavigate } from "react-router-dom";
function FirstComponent() {
  const [devWidth, setDevWidth] = useState(
    window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  );

  useEffect(() => {
    const handleResize = () =>
      setDevWidth(
        window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
      );
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const videoId = process.env.REACT_APP_VIDEO_ID;
  let texts = lpTexts.firstComponent;

  const navigate = useNavigate();
  return (
    <div className="all-first">
      <div className="first-left">
        <div className="first-text1">{texts.text1}</div>
        <div className="first-text2">{texts.text2}</div>
        <div className="first-text3">{texts.text3}</div>
        <div className="first-pillars">
          <Pillars
            name="Pioneirismo"
            iconSVG={devWidth > 991 ? IconPioneering : IconPioneeringMob}
          />
          <Pillars
            name="Times inovadores"
            iconSVG={
              devWidth > 991 ? IconInnovationTeams : IconInnovationTeamsMob
            }
          />
          <Pillars
            name="Mente aberta"
            iconSVG={devWidth > 991 ? IconOpenMind : IconOpenMindMob}
          />
        </div>
      </div>
      <div className="first-right">
        <div className="first-text4">{texts.text4}</div>
        <div className="first-video">
          <iframe
            width={devWidth > 991 ? "560" : devWidth * 0.8}
            height={devWidth > 991 ? "315" : ""}
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div
          className="first-button"
          onClick={() => {
            navigate("/diagnostico");
            window.scrollTo({ top: 0 });
          }}
        >
          <ButtonDiagnosis buttonText="Preencher diagnóstico" />
        </div>
      </div>
    </div>
  );
}
export default FirstComponent;
